import React, { useEffect, useRef, useState } from "react";
import { fromJS, List, Map } from "immutable";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { IconButton, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import {
  Add,
  CallMade,
  DeleteForever,
  Edit,
  Refresh,
  Search,
} from "@material-ui/icons";
import { getValue } from "../../helpers/DataUtils";
import {
  getAVVALUserId,
  getUser,
  getUserWarehouseId,
  hasUserPermission,
} from "../../reducers/ProfileReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
} from "../../components/ui-core/FlexBox";
import { DIALOG, RENDER } from "../../components/orders-core/MUITable";
import _ from "lodash";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import ChipTextField from "../../components/deprecated/ChipTextField";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  COD,
  CUSTOMS_FEE_PERCENTAGE,
  CUSTOMS_FEE_V2,
  RTO_INTERNATI0NAL,
} from "../../constants/OrderChargeItemTypes";
import {
  getCheckId,
  getOrder2,
  setReceipt,
} from "../../api/v2/admin/AdminOrderApi";
import {
  customsFeePercentage,
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../reducers/MarketplaceReducer";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import OrderListServiceType from "../../components/orders-core/OrderListServiceType";
import AvatarWithName2 from "../../components/avatars/AvatarWithName2";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import { formatOrderStatusCodeLocalization } from "../../helpers/OrderHelper";
import CustomsFeeCreateDialog from "../../components/orders-core/CustomsFeeCreateDialog";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import {
  deleteCustomsChargeItem,
  getCustomsOrderList,
  createCustomsChargeItem,
  updateCustomsChargeItem,
} from "../../api/admin/AdminCustomsPaymentApi";
import CustomsFeeUpdateDialog from "../../components/orders-core/CustomsFeeUpdateDialog";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import { BOLD } from "../../components/ui-core/Text";
import BatchRTOPPriceOrdersDialog from "../../components/orders-core/BatchRTOPPriceOrdersDialog";
import { RETURNED_TO_ORIGIN } from "../../constants/OrderStatusCodes";
import { CARD, CASH } from "../../constants/OrderPaymentTypes";
import {
  updateOrderIssuance,
  updateRecipientPostcode,
  updateRTOInternational,
  uploadOrderSignature,
} from "../../api/admin/AdminOrderApi";
import { avvalSearchUrl } from "../../components/orders-core/CommissionTINDialog";
import { getPDF } from "../../helpers/FormUtils";
import {
  COD_RECEIPT_URL,
  CUSTOMS_RECEIPT_URL,
  IS_DOWNLOADING,
  IS_PRINTING,
  RefundInfo,
  RTO_RECEIPT_URL,
  serviceId,
  urlAVVAL,
} from "../../components/orders-core/AdminPostForm";
import fp from "lodash/fp";
import OrderDetailsDialogIssuance2 from "../../components/order-details-dialog/OrderDetailsDialogIssuance2";
import {
  formatDateTimeToUrlWithSeconds,
  formatErrorMessage,
} from "../../helpers/FormatUtils";
import AdminOrderDetailsDialogWrapperForOrderList from "../../wrappers/admin/AdminOrderDetailsDialogWrapperForOrderList";
import OrdersMUITable from "../../components/orders-core/OrdersMUITable";
import { calculateVAT } from "../../components/order-details-dialog/v2/OrderDetailsDialog";
import BatchRTOOrdersFormDialogWrapper from "../../wrappers/admin/BatchRTOOrdersFormDialogWrapper";
import OrderSortingRedirectOrderDialog from "../../components/order-outbound-sorting/OrderSortingRedirectOrderDialog";
import ResponseError from "../../helpers/ResponseError";
import AdminBatchUpdatesItemDialogWrapper from "../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import BatchAssignOrdersToDriverFormDialogWrapper from "../../wrappers/admin/BatchAssignOrdersToDriverFormDialogWrapper";
import OrderDetailsDialogCustoms from "../../components/order-details-dialog/OrderDetailsDialogCustoms";
import DateTimeCell from "../../components/data-list/DateTimeCell";

const options = commissionTin => ({
  method: "POST",
  headers: {
    Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    search_inn_comm: {
      comm_inn: commissionTin,
    },
  }),
});

const useStyles = makeStyles({
  pageFab: { bottom: "64px" },
  appBarRightAction: { height: "100%" },
  appBarRightActionToggle: {
    whiteSpace: "nowrap",
    marginLeft: "12px",
  },
  mr10: {
    marginRight: 10,
  },
  pLR1rem: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  filterToggle: { marginTop: "12px" },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
  titleWithBorder2: {
    textAlign: "center",
    borderBottom: "1px solid #eeeeee",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #eee",
  },
  tabs: {
    flexGrow: 1,
  },
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  createSimpleButton: {
    "& .MuiButton-contained:hover": {
      backgroundColor: "#1976d2",
      color: "#fff",
    },
  },
});
const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userWarehouse = getUser(state).get("warehouse");
      return {
        userIdAVVAL: getAVVALUserId(state),
        hasBatchUpdatePermission: hasUserPermission(
          state,
          "ADMIN_ORDER_BATCH_MODAL_VIEW",
        ),
        createSimpleOrder: hasUserPermission(state, "ADMIN_SIMPLE_ORDER"),
        userWarehouse,
        i18n: getMessages(state),
        userWarehouseId: getUserWarehouseId(state),
        isOtpSettingsServiceType: isOtpSettingsServiceType(state),
        isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
        customsFee: customsFeePercentage(state),
      };
    },
    { showSuccessMessage, showErrorMessage },
  ),
);

const AdminCustomsPaymentListContainer = ({
  i18n,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  customsFee,
  userIdAVVAL,
  // eslint-disable-next-line no-shadow
  isOtpSettingsServiceType,
  // eslint-disable-next-line no-shadow
  isOtpSettingsTransportationType,
  userWarehouseId,
}) => {
  const classes = useStyles();
  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);
  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRTOPrice, setIsOpenRTOPrice] = useState(false);
  const [isLoadingRTOPrice, setIsLoadingRTOPrice] = useState(false);
  const [orderId, setOrderId] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [isOpenBrief, setIsOpenBrief] = useState(false);
  const [isOpenCustomsFeePrice, setIsOpenCustomsFeePrice] = useState(false);
  const [isLoadingCustomsFeePrice, setIsLoadingCustomsFeePrice] = useState(
    false,
  );
  const [isOpenIssuance, setIsOpenIssuance] = useState(false);
  const [isOpenIssuanceCustoms, setIsOpenIssuanceCustoms] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [id, setId] = useState(null);

  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 0,
    size: 20,
  });

  const [isLoadingCOD, setIsLoadingCOD] = useState(false);

  const [stateReceiptCOD, setStateReceiptCOD] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: COD_RECEIPT_URL,
  });

  const [stateReceiptCustoms, setStateReceiptCustoms] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: CUSTOMS_RECEIPT_URL,
  });

  const [stateReceiptRTO, setStateReceiptRTO] = useState({
    [IS_PRINTING]: false,
    [IS_DOWNLOADING]: false,
    url: RTO_RECEIPT_URL,
  });

  const refreshOrderList = () =>
    setFilter({ ...filter, refresh: !filter.refresh });

  const [order, setOrder] = useState({});

  const chargeItems = _.get(order, "charge_items", []);
  const chargeItemCustom =
    _.find(chargeItems, { charge_type: CUSTOMS_FEE_V2 }) || {};

  const chargeItemCustomFeePercentage =
    _.find(chargeItems, { charge_type: CUSTOMS_FEE_PERCENTAGE }) || {};

  const chargeItemCOD = _.find(chargeItems, { charge_type: COD }) || {};
  const chargeItemRTO =
    _.find(chargeItems, { charge_type: RTO_INTERNATI0NAL }) || {};
  const isHaveCOD = chargeItemCOD.charge > 0;
  const isHaveCustom = chargeItemCustom.charge > 0;
  const barcode = _.get(order, "barcode");
  const contentItems = _.get(order, "content_items", []);
  const [checkID, setCheckID] = useState(0);

  const [refreshChekId, setRefreshChekId] = useState(false);
  const [isRefreshOrderData, setIsRefreshOrderData] = useState(false);
  const [isOpenRedirect, setIsOpenRedirect] = useState(false);
  const [isOpenDispatch, setIsOpenDispatch] = useState(false);
  const [isOpenRTO, setIsOpenRTO] = useState(false);
  const [batchId, setBatchId] = useState(false);
  const [isHybrid, setIsHybrid] = useState(false);

  const [errorOtpData, setErrorOtpError] = useState("");

  useEffect(() => {
    if (!(checkID > 0)) {
      getCheckId().then(res => setCheckID(_.get(res, "data")));
    }
  }, [refreshChekId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCOD && isOpenIssuance && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenIssuance, isHaveCOD]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isHaveCustom && isOpenIssuanceCustoms && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenIssuanceCustoms, isHaveCustom]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOpenRTOPrice && !(checkID > 0)) {
      const refresh = setInterval(() => setRefreshChekId(prev => !prev), 300);
      return () => clearInterval(refresh);
    }
  }, [checkID, isOpenRTOPrice]);

  useEffect(() => {
    if (orderId || id) {
      getOrder2(orderId || id)
        .then(res => {
          setOrder(_.get(res, "data", {}));
          setIsHybrid(_.get(res, "data.hybrid", false));
        })
        .catch(err => showErrorMessage1(err));
    }
  }, [orderId, id]);

  useEffect(() => {
    setIsLoading(true);

    getCustomsOrderList(filter)
      .then(res => {
        const tempTotal = _.get(res, "data.total");
        setIsLoading(false);
        setOrderList(_.get(res, "data.list"));
        setTotal(tempTotal);
      })
      .catch(err => {
        setIsLoading(false);
        showErrorMessage1(err);
      });
  }, [filter]);

  const handleChangePage = (event, page) =>
    setFilter({
      ...filter,
      page,
      with_totals: false,
    });
  const handleChangeRowsPerPage = event =>
    setFilter({
      ...filter,
      size: parseInt(event.target.value, 10),
      page: 0,
      with_totals: false,
    });

  const onDelete = removeId => {
    deleteCustomsChargeItem(removeId)
      .then(() => {
        setDeleteId(0);
        showSuccessMessage1(
          i18n.get("successfully_removed", "Successfully Removed"),
        );
        refreshOrderList();
      })
      .catch(error => {
        showErrorMessage1(error);
        setDeleteId(0);
      });
  };

  const checkAVVALres = response => {
    if (response === null) {
      showErrorMessage1(
        i18n.get(
          "unexpected_error_occurred_and_payment_failed_please_contact_your_manage_or_try_later",
        ),
      );
      return false;
    } else if (
      response &&
      getValue(response, "resInfo.result.QRCodeURL") === null
    ) {
      showErrorMessage1(i18n.get("the_payment_was_not_successful"));
      return false;
    } else if (_.get(response, "msg") === "error") {
      const errorMessage = `${i18n.get("error_msg_from_avval")} : ${_.get(
        response,
        "resInfo.message",
      )}`;
      showErrorMessage1(errorMessage);
      return false;
    }
    return true;
  };

  const createAVVALDataRTO = (paymentType, orderDto) => {
    const servicesAvval = [];
    const customerName = _.get(orderDto, "customer.name", "");
    const Items = [];
    const charges = _.get(orderDto, "charge_items", []);
    const chargeItemRTOInternational =
      _.find(charges, { charge_type: RTO_INTERNATI0NAL }) || {};
    const createService = v =>
      servicesAvval.push({
        type: _.get(v, "charge_type"),
        id: _.get(v, "id"),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });
    const createRequest = (v, price, Amount) =>
      Items.push({
        Name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
        Barcode: _.get(v, "barcode", "$10406001001000000$"),
        Label: _.get(v, "label", ""),
        Spic: _.get(v, "spic", "10105001001000000"),
        Units: _.get(v, "units", 1221),
        Price: Number(_.multiply(price, 100).toFixed(2)),
        VatPercent: 12,
        PackageCode: "",
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
        Vat: calculateVAT(price),
        Amount: Number(_.multiply(Amount, 100).toFixed(2)),
        Discount: _.get(v, "discount", 0),
        Other: _.get(v, "other", 0),
      });

    charges
      .filter(x => x.charge_type === RTO_INTERNATI0NAL)
      .forEach(v => {
        createService(v);
        createRequest(v, v.charge);
      });

    const ReceivedCash =
      paymentType === CASH
        ? Number((_.get(chargeItemRTOInternational, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCard =
      paymentType === CARD
        ? Number((_.get(chargeItemRTOInternational, "charge") * 100).toFixed(2))
        : 0;
    return {
      method: "sale-services",
      userId: userIdAVVAL,
      tosender: 1,
      serviceId,
      barcode: _.get(orderDto, "barcode", ""),
      checkID,
      chNumber: _.get(orderDto, "chNumber", orderId),
      services: servicesAvval,
      request: {
        Items,
        ReceivedCash,
        ReceivedCard,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            _.get(chargeItemRTOInternational, "card_type") === "CORPORATIVE"
              ? 1
              : _.get(chargeItemRTOInternational, "card_type") === "PRIVATE"
              ? 2
              : "",
          PPTID: _.get(chargeItemRTOInternational, "ppt_id"),
        },
      },
    };
  };

  const createAVVALData = (paymentType, cardType, pptId, QQS) => {
    const customerName = _.get(order, "customer.name", "");
    const servicesCod = [];
    const commissionINNItems = [];
    contentItems.map(item => {
      const vat = parseFloat(
        ((Number(_.get(item, "price")) * Number(_.get(item, "qty"))) / 1.12) *
          0.12,
      ).toFixed(2);

      const Amount = Number(_.get(item, "qty")) * 1000;
      const Price =
        Number(_.get(item, "qty")) * Number(_.get(item, "price", 0)) * 100;
      servicesCod.push({
        type: "COD",
        id: Number(_.get(item, "service_id", "")),
        name:
          customerName && customerName.length
            ? customerName.slice(0, 254)
            : " ",
      });

      return commissionINNItems.push({
        Name:
          _.get(item, "name", "") && _.get(item, "name", "").length
            ? _.get(item, "name", "").slice(0, 254)
            : "",
        Barcode: _.get(item, "barcode", "$10406001001000000$"),
        Label: "",
        Spic: _.get(item, "spic", "10105001001000000"),
        Units: 0,
        Price,
        VatPercent: QQS || 0,
        PackageCode: _.get(item, "package_code", 0),
        CommissionInfo: {
          TIN:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 9
              ? _.get(item, "commission_tin", "")
              : "",
          PINFL:
            _.get(item, "commission_tin", "") &&
            _.get(item, "commission_tin", "").length === 14
              ? _.get(item, "commission_tin", "")
              : "",
        },
        Vat: QQS > 0 ? Number((vat * 100).toFixed(0)) : 0,
        Amount,
        OwnerType: 0,
        Discount: _.get(item, "discount", 0),
        Other: _.get(item, "other", 0),
      });
    });

    const ReceivedCashCOD =
      paymentType === CASH
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCOD =
      paymentType === CARD
        ? Number((_.get(chargeItemCOD, "charge") * 100).toFixed(2))
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(order, "barcode", ""),
      checkID,
      chNumber: _.get(order, "chNumber", orderId),
      services: servicesCod,
      request: {
        Items: commissionINNItems,
        ReceivedCash: ReceivedCashCOD,
        ReceivedCard: ReceivedCardCOD,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };

  const createAVVALDataCustoms = (paymentType, cardType, pptId) => {
    const services = [
      {
        type: "other_pay",
        id: 382,
        name: "Bojxona xizmati",
      },
    ];
    const items = [
      {
        Name: "Bojxona xizmati tolovi",
        Barcode: "$10406001001000000$",
        Label: "",
        Spic: "$10406001001000000$",
        Units: 0,
        Price: _.get(chargeItemCustom, "charge"),
        VatPercent: 0,
        PackageCode: "1516277",
        CommissionInfo: {
          TIN: "202450011",
          PINFL: "",
        },
        Vat: 0,
        Amount: 1000,
        OwnerType: 0,
        Discount: 0,
        Other: 0,
      },
      {
        Name: "To'lovlarni qabul qilish xizmati",
        Barcode: "$10406001001000000$",
        Label: "",
        Spic: "10406001001000000",
        Units: 0,
        PackageCode: "1508530",
        Price: _.get(chargeItemCustomFeePercentage, "charge"),
        VatPercent: 12,
        Vat: 0,
        Amount: 1000,
        Discount: 0,
        Other: 0,
        CommissionInfo: {
          TIN: "",
        },
        OwnerType: 2,
      },
    ];

    const ReceivedCashCustoms =
      paymentType === CASH
        ? Number((_.get(chargeItemCustom, "charge") * 100).toFixed(2))
        : 0;
    const ReceivedCardCustoms =
      paymentType === CARD
        ? Number((_.get(chargeItemCustom, "charge") * 100).toFixed(2))
        : 0;

    const ReceivedCashCustomsFeePercentage =
      paymentType === CASH
        ? Number(
            (_.get(chargeItemCustomFeePercentage, "charge") * 100).toFixed(2),
          )
        : 0;
    const ReceivedCardCustomsFeePercentage =
      paymentType === CARD
        ? Number(
            (_.get(chargeItemCustomFeePercentage, "charge") * 100).toFixed(2),
          )
        : 0;

    return {
      method: "sale-services",
      userId: userIdAVVAL,
      serviceId,
      barcode: _.get(order, "barcode", ""),
      checkID,
      chNumber: orderId,
      services,
      request: {
        Items: items,
        ReceivedCash: ReceivedCashCustoms + ReceivedCashCustomsFeePercentage,
        ReceivedCard: ReceivedCardCustoms + ReceivedCardCustomsFeePercentage,
        Time: formatDateTimeToUrlWithSeconds(new Date()),
        RefundInfo,
        Location: {
          Latitude: 41.316571,
          Longitude: 69.284233,
        },
        ExtraData: {
          TIN: "",
          PINFL: "",
          PhoneNumber: "",
          CarNumber: "",
          QRPaymentID: "",
          QRPaymentProvider: 0,
          CashedOutFromCard: 0,
          CardType:
            cardType === "CORPORATIVE" ? 1 : cardType === "PRIVATE" ? 2 : "",
          PPTID: pptId,
        },
      },
    };
  };

  const otpSettingsServiceTypes = isOtpSettingsServiceType
    ? isOtpSettingsServiceType.toJS()
    : [];
  const otpSettingsTransportationTypes = isOtpSettingsTransportationType
    ? isOtpSettingsTransportationType.toJS()
    : [];

  const showOtpSettings =
    otpSettingsServiceTypes.includes(_.get(order, "service_type.code", "")) &&
    otpSettingsTransportationTypes.includes(
      _.get(order, "transportation_type", ""),
    );

  const optionsPayment = tosender => ({
    method: "POST",
    headers: {
      Authorization: `Basic bG9naXN0aWthX3NlYXJjaDpFZGUkeWEwMzRjdSFXMzBMdFE/ZG8=`,
      "Content-Type": "application/json",
      Cookie: "PHPSESSID=5lgnfmu5ov5vgb3b8g7tdb9rg2",
    },
    body: JSON.stringify({
      search_chek: {
        tosender,
        barcode,
        method: "sale-services",
      },
    }),
  });

  return (
    <AdminAppLayout title={i18n.get("order_customs_fee", "Order customs fee")}>
      <ConfirmDialog
        open={deleteId > 0}
        onRequestClose={() => {
          setDeleteId(0);
        }}
        onConfirm={() => {
          onDelete(deleteId);
          setDeleteId(0);
        }}
      >
        {i18n.get(
          "are_you_sure_you_want_delete",
          "Are you sure you want delete?",
        )}
      </ConfirmDialog>

      <BatchRTOOrdersFormDialogWrapper
        open={isOpenRTO}
        isHybrid={isHybrid}
        initialValues={{
          orderBarcodes: [barcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenRTO(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={response => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenRTO(false);
          setBatchId(_.get(response, "data.id"));
        }}
      />

      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        onRequestClose={() => {
          setBatchId(false);
        }}
      />

      <OrderSortingRedirectOrderDialog
        open={isOpenRedirect}
        order={fromJS(order)}
        onSubmit={({ index }) =>
          updateRecipientPostcode({
            index: fp.get("name", index),
            barcodes: [barcode],
            is_redirected: true,
          }).catch(ResponseError.throw)
        }
        onSubmitFail={showErrorMessage1}
        onSubmitSuccess={() => {
          setIsOpenRedirect(false);
          setIsRefreshOrderData(!isRefreshOrderData);
          showSuccessMessage1(i18n.get("successfully_updated"));
        }}
        onRequestClose={() => {
          setIsOpenRedirect(false);
          setIsOpenBrief(true);
        }}
      />

      <CustomsFeeCreateDialog
        open={isOpenCustomsFeePrice}
        isLoadingRTOPrice={isLoadingCustomsFeePrice}
        close={() => {
          setIsOpenCustomsFeePrice(false);
        }}
        initialValues={{
          percentage: Number(customsFee),
        }}
        onSubmit={values => {
          setIsLoadingCustomsFeePrice(true);

          createCustomsChargeItem({
            charge: values.charge,
            order_id: values.orderId,
          })
            .then(r => {
              if (r && r.status === "success") {
                showSuccessMessage1(
                  i18n.get("successfully_created", "Successfully Created"),
                );
                setIsOpenCustomsFeePrice(false);
                refreshOrderList();
                setIsLoadingCustomsFeePrice(false);
              }
            })
            .catch(err => {
              showErrorMessage1(err);
              setIsLoadingCustomsFeePrice(false);
            });
        }}
      />

      <CustomsFeeUpdateDialog
        open={Boolean(orderId)}
        isLoadingRTOPrice={isLoadingCustomsFeePrice}
        close={() => {
          setOrderId(null);
          setOrder({});
        }}
        initialValues={{
          orderId,
          barcode: [getValue(order, "barcode")],
          percentage: Number(getValue(order, "customs_fee_percentage")),
          charge: getValue(chargeItemCustom, "charge") || 0,
          total: getValue(order, "customs_fee_percentage")
            ? ((Number(getValue(order, "customs_fee_percentage")) + 100) *
                getValue(chargeItemCustom, "charge")) /
              100
            : getValue(chargeItemCustom, "charge"),
        }}
        status={getValue(order, "status")}
        onSubmit={values => {
          setIsLoadingCustomsFeePrice(true);
          updateCustomsChargeItem({
            charge: values.charge,
            order_id: values.orderId,
          })
            .then(r => {
              if (r && r.status === "success") {
                showSuccessMessage1(
                  i18n.get("successfully_created", "Successfully Created"),
                );
                setIsLoadingCustomsFeePrice(false);
                refreshOrderList();
                setOrderId(null);
                setOrder({});
              }
            })
            .catch(err => {
              showErrorMessage1(err);
              setIsLoadingCustomsFeePrice(false);
            });
        }}
      />

      <BatchRTOPPriceOrdersDialog
        open={isOpenRTOPrice}
        isLoadingRTOPrice={isLoadingRTOPrice}
        close={() => {
          setIsOpenRTOPrice(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          barcode: _.get(order, "barcode", ""),
          status: RETURNED_TO_ORIGIN,
          paymentType: getValue(chargeItemRTO, "payment_type")
            ? getValue(chargeItemRTO, "payment_type")
            : CASH,
          price: getValue(chargeItemRTO, "charge")
            ? getValue(chargeItemRTO, "charge")
            : _.get(order, "package_type.price.total", ""),
          cardType: getValue(chargeItemRTO, "card_type"),
          pptId: getValue(chargeItemRTO, "ppt_id"),
        }}
        onSubmit={values => {
          setIsLoadingRTOPrice(true);
          updateRTOInternational(toSnakeCase(values))
            .then(r => {
              if (r && r.status === "success") {
                setTimeout(() => {
                  fetch(avvalSearchUrl, optionsPayment(1))
                    .then(response => response.json())
                    .then(rescheck => {
                      if (rescheck && rescheck.res_id === 103) {
                        setReceipt(orderId, {
                          check_number: orderId,
                          receipt: {
                            TerminalID: getValue(rescheck, "data.terminal_id"),
                            ReceiptSeq: getValue(
                              rescheck,
                              "data.tartib_raqami",
                            ),
                            DateTime: getValue(rescheck, "data.date_time"),
                            FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                            AppletVersion: getValue(rescheck, "data.versiya"),
                            QRCodeURL: getValue(rescheck, "data.link"),
                            checkID,
                          },
                          type: "RTO",
                          rto_payment_type: _.get(values, "paymentType", CASH),
                        })
                          .then(resAvval => {
                            if (resAvval) {
                              setIsRefreshOrderData(!isRefreshOrderData);

                              refreshOrderList();

                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                              showSuccessMessage1(
                                i18n.get(
                                  "successfully_created",
                                  "Successfully Created",
                                ),
                              );

                              setCheckID(0);
                            }
                          })
                          .catch(err => {
                            setIsLoadingRTOPrice(false);
                            showErrorMessage1(err);
                          })
                          .finally(() => {
                            setIsLoadingRTOPrice(false);
                            getPDF(
                              stateReceiptRTO,
                              setStateReceiptRTO,
                              IS_PRINTING,
                              orderId,
                              showErrorMessage1,
                            );
                            setIsOpenRTOPrice(false);
                            setIsOpenBrief(true);
                          });
                      } else {
                        fetch(urlAVVAL, {
                          method: "POST",
                          body: JSON.stringify(
                            createAVVALDataRTO(
                              _.get(values, "paymentType", CASH),
                              _.get(r, "data", {}),
                            ),
                          ),
                        })
                          .then(response => response.json())
                          .then(res => {
                            if (res && getValue(res, "code") === "1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo.code")),
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (res && getValue(res, "code") === "-1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo")),
                                  getValue(res, "resInfo"),
                                ),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (
                              res &&
                              (getValue(res, "resInfo.result.QRCodeURL") ===
                                null ||
                                !getValue(res, "resInfo.result.QRCodeURL"))
                            ) {
                              showErrorMessage1(
                                i18n.get("the_payment_was_not_successful"),
                              );
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            } else if (res && res.msg === "succes") {
                              if (checkAVVALres(res)) {
                                setReceipt(orderId, {
                                  check_number: orderId,
                                  receipt: {
                                    ..._.get(res, "resInfo.result"),
                                    checkID,
                                  },
                                  type: "RTO",
                                  rto_payment_type: _.get(
                                    values,
                                    "paymentType",
                                    CASH,
                                  ),
                                })
                                  .then(resAvval => {
                                    if (resAvval) {
                                      setIsRefreshOrderData(
                                        !isRefreshOrderData,
                                      );

                                      refreshOrderList();

                                      setTimeout(
                                        () => setIsLoadingRTOPrice(false),
                                        1500,
                                      );
                                      showSuccessMessage1(
                                        i18n.get(
                                          "successfully_created",
                                          "Successfully Created",
                                        ),
                                      );
                                    }

                                    setCheckID(0);
                                  })
                                  .catch(e => {
                                    setIsLoadingRTOPrice(false);
                                    showErrorMessage1(e);
                                  })
                                  .finally(() => {
                                    setIsOpenRTOPrice(false);
                                    getPDF(
                                      stateReceiptRTO,
                                      setStateReceiptRTO,
                                      IS_PRINTING,
                                      orderId,
                                      showErrorMessage1,
                                    );
                                    setIsOpenRTOPrice(false);
                                    setIsOpenBrief(true);
                                  });
                              }
                            } else {
                              setTimeout(
                                () => setIsLoadingRTOPrice(false),
                                1500,
                              );
                            }
                          })
                          .catch(() => {
                            showErrorMessage1(
                              i18n.get("user_is_not_connected_to_avval"),
                            );
                            setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                          });
                      }
                    })
                    .catch(() => {
                      setTimeout(() => setIsLoadingRTOPrice(false), 1500);
                    }, 300);
                }, 1000);
              }
            })
            .catch(err => {
              showErrorMessage1(err);
              setTimeout(() => setIsLoadingRTOPrice(false), 1500);
            });
        }}
      />

      <OrderDetailsDialogIssuance2
        errorOtpData={errorOtpData}
        setErrorOtpError={setErrorOtpError}
        showOtpSettings={showOtpSettings}
        isLoadingCOD={isLoadingCOD}
        open={isOpenIssuance}
        isHaveCOD={isHaveCOD}
        chargeItemCOD={_.get(chargeItemCOD, "charge")}
        close={() => {
          setIsOpenIssuance(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          name: _.get(order, "recipient_data.name", ""),
          without_otp: !showOtpSettings,
        }}
        header={i18n.get("recipient", "Recipient")}
        orderId={orderId}
        onSubmit={values => {
          setIsLoadingCOD(true);
          if (isHaveCOD) {
            fetch(avvalSearchUrl, optionsPayment())
              .then(response => response.json())
              .then(rescheck => {
                if (rescheck && rescheck.res_id === 103) {
                  fetch(avvalSearchUrl, options(contentItems[0].commission_tin))
                    .then(response => response.json())
                    .then(resAvval => {
                      if (resAvval && resAvval.mess === "success") {
                        updateOrderIssuance(orderId, toSnakeCase(values))
                          .then(() => {
                            setReceipt(orderId, {
                              vat_active: resAvval.data.nds_active,
                              check_number: orderId,
                              cod_payment_type: _.get(
                                values,
                                "codPaymentType",
                                CASH,
                              ),
                              type: "COD",
                              card_type: getValue(values, "cardType"),
                              ppt_id: getValue(values, "pptId"),
                              receipt: {
                                TerminalID: getValue(
                                  rescheck,
                                  "data.terminal_id",
                                ),
                                ReceiptSeq: getValue(
                                  rescheck,
                                  "data.tartib_raqami",
                                ),
                                DateTime: getValue(rescheck, "data.date_time"),
                                FiscalSign: getValue(
                                  rescheck,
                                  "data.fiskal_belgi",
                                ),
                                AppletVersion: getValue(
                                  rescheck,
                                  "data.versiya",
                                ),
                                QRCodeURL: getValue(rescheck, "data.link"),
                                checkID,
                              },
                            })
                              .then(() => {
                                uploadOrderSignature(orderId, {
                                  name: values.name,
                                  order_id: orderId,
                                  phone: "",
                                  photo_id: values.photoId,
                                  signature_id: values.signatureId,
                                  type: "delivery_signature",
                                })
                                  .then(() => {
                                    setIsRefreshOrderData(!isRefreshOrderData);
                                    showSuccessMessage1(
                                      i18n.get("successfully_updated"),
                                    );
                                    setIsOpenIssuance(false);
                                    setCheckID(0);
                                    setIsOpenBrief(true);
                                  })
                                  .catch(err => {
                                    setIsLoadingCOD(false);
                                    showErrorMessage1(err);
                                  })
                                  .finally(() => {
                                    setIsLoadingCOD(false);
                                    getPDF(
                                      stateReceiptCOD,
                                      setStateReceiptCOD,
                                      IS_PRINTING,
                                      orderId,
                                      showErrorMessage1,
                                    );
                                  });
                              })
                              .catch(err => {
                                setIsLoadingCOD(false);
                                showErrorMessage1(err);
                              });
                          })
                          .catch(err => {
                            setErrorOtpError(formatErrorMessage(err));

                            setIsLoadingCOD(false);
                            showErrorMessage1(err);
                          });
                      }
                    });
                } else {
                  fetch(avvalSearchUrl, options(contentItems[0].commission_tin))
                    .then(response => response.json())
                    .then(resAvval => {
                      if (resAvval && resAvval.mess === "success") {
                        const QQS = resAvval.data.nds_active ? 12 : 0;
                        fetch(urlAVVAL, {
                          method: "POST",
                          body: JSON.stringify(
                            createAVVALData(
                              _.get(values, "codPaymentType", CASH),
                              getValue(values, "cardType"),
                              getValue(values, "pptId"),
                              QQS,
                            ),
                          ),
                        })
                          .then(response => response.json())
                          .then(res => {
                            if (res && getValue(res, "code") === "1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo.code")),
                                ),
                              );
                              setIsLoadingCOD(false);
                            } else if (res && getValue(res, "code") === "-1") {
                              showErrorMessage1(
                                i18n.get(
                                  fp.toString(getValue(res, "resInfo")),
                                  getValue(res, "resInfo"),
                                ),
                              );
                              setIsLoadingCOD(false);
                            } else if (
                              res &&
                              (getValue(res, "resInfo.result.QRCodeURL") ===
                                null ||
                                !getValue(res, "resInfo.result.QRCodeURL"))
                            ) {
                              showErrorMessage1(
                                i18n.get("the_payment_was_not_successful"),
                              );
                              setIsLoadingCOD(false);
                            } else if (res && res.msg === "succes") {
                              if (checkAVVALres(res)) {
                                updateOrderIssuance(
                                  orderId,
                                  toSnakeCase(values),
                                )
                                  .then(() => {
                                    setReceipt(orderId, {
                                      check_number: orderId,
                                      vat_active: resAvval.data.nds_active,
                                      cod_payment_type: _.get(
                                        values,
                                        "codPaymentType",
                                        CASH,
                                      ),
                                      type: "COD",
                                      receipt: {
                                        ..._.get(res, "resInfo.result"),
                                        checkID,
                                      },
                                    })
                                      .then(() => {
                                        uploadOrderSignature(orderId, {
                                          name: values.name,
                                          order_id: orderId,
                                          phone: "",
                                          photo_id: values.photoId,
                                          signature_id: values.signatureId,
                                          type: "delivery_signature",
                                        })
                                          .then(res2 => {
                                            setIsRefreshOrderData(
                                              !isRefreshOrderData,
                                            );
                                            showSuccessMessage1(res2.status);
                                            setIsOpenIssuance(false);
                                            setCheckID(0);
                                            setIsOpenBrief(true);
                                          })
                                          .catch(err => {
                                            setIsLoadingCOD(false);
                                            showErrorMessage1(err);
                                          })
                                          .finally(() => {
                                            setIsLoadingCOD(false);
                                            getPDF(
                                              stateReceiptCOD,
                                              setStateReceiptCOD,
                                              IS_PRINTING,
                                              orderId,
                                              showErrorMessage1,
                                            );
                                          });
                                      })
                                      .catch(err => {
                                        setIsLoadingCOD(false);
                                        showErrorMessage1(err);
                                      });
                                  })
                                  .catch(err => {
                                    setErrorOtpError(formatErrorMessage(err));

                                    setIsLoadingCOD(false);
                                    showErrorMessage1(err);
                                  });
                              }
                            } else {
                              setIsLoadingCOD(false);
                            }
                          })
                          .catch(() => {
                            setIsLoadingCOD(false);
                            showErrorMessage1(
                              i18n.get("user_is_not_connected_to_avval"),
                            );
                          });
                      }
                    });
                }
              })
              .catch(() => {
                setIsLoadingCOD(false);
              });
          } else {
            updateOrderIssuance(orderId, toSnakeCase(values))
              .then(() => {
                uploadOrderSignature(orderId, {
                  name: values.name,
                  order_id: orderId,
                  phone: "",
                  photo_id: values.photoId,
                  signature_id: values.signatureId,
                  type: "delivery_signature",
                })
                  .then(res => {
                    setIsRefreshOrderData(!isRefreshOrderData);
                    showSuccessMessage1(res.status);
                    setIsOpenIssuance(false);
                    setIsOpenBrief(true);
                  })
                  .catch(err => showErrorMessage1(err));
              })
              .catch(err => {
                setErrorOtpError(formatErrorMessage(err));
                showErrorMessage1(err);
              })
              .finally(() => setIsLoadingCOD(false));
          }
        }}
      />

      <OrderDetailsDialogCustoms
        errorOtpData={errorOtpData}
        setErrorOtpError={setErrorOtpError}
        showOtpSettings={showOtpSettings}
        isLoading={isLoadingCOD}
        open={isOpenIssuanceCustoms}
        isHave={isHaveCustom}
        chargeItem={_.get(chargeItemCustom, "charge")}
        chargeItemCustomFeePercentage={_.get(
          chargeItemCustomFeePercentage,
          "charge",
        )}
        close={() => {
          setIsOpenIssuanceCustoms(false);
          setIsOpenBrief(true);
        }}
        initialValues={{
          name: _.get(order, "recipient_data.name", ""),
          without_otp: !showOtpSettings,
        }}
        header={i18n.get("recipient", "Recipient")}
        orderId={orderId}
        onSubmit={values => {
          setIsLoadingCOD(true);
          fetch(avvalSearchUrl, optionsPayment())
            .then(response => response.json())
            .then(rescheck => {
              if (rescheck && rescheck.res_id === 103) {
                updateOrderIssuance(orderId, toSnakeCase(values))
                  .then(() => {
                    setReceipt(orderId, {
                      check_number: orderId,
                      payment_type: _.get(values, "paymentType", CASH),
                      type: "CUSTOMS",
                      card_type: getValue(values, "cardType"),
                      ppt_id: getValue(values, "pptId"),
                      receipt: {
                        TerminalID: getValue(rescheck, "data.terminal_id"),
                        ReceiptSeq: getValue(rescheck, "data.tartib_raqami"),
                        DateTime: getValue(rescheck, "data.date_time"),
                        FiscalSign: getValue(rescheck, "data.fiskal_belgi"),
                        AppletVersion: getValue(rescheck, "data.versiya"),
                        QRCodeURL: getValue(rescheck, "data.link"),
                        checkID,
                      },
                    })
                      .then(() => {
                        uploadOrderSignature(orderId, {
                          name: values.name,
                          order_id: orderId,
                          phone: "",
                          photo_id: values.photoId,
                          signature_id: values.signatureId,
                          type: "delivery_signature",
                        })
                          .then(() => {
                            setIsRefreshOrderData(!isRefreshOrderData);
                            showSuccessMessage1(
                              i18n.get("successfully_updated"),
                            );
                            setIsOpenIssuanceCustoms(false);
                            setCheckID(0);
                            setIsOpenBrief(true);
                          })
                          .catch(err => {
                            setIsLoadingCOD(false);
                            showErrorMessage1(err);
                          })
                          .finally(() => {
                            setIsLoadingCOD(false);
                            getPDF(
                              stateReceiptCustoms,
                              setStateReceiptCustoms,
                              IS_PRINTING,
                              orderId,
                              showErrorMessage1,
                            );
                          });
                      })
                      .catch(err => {
                        setIsLoadingCOD(false);
                        showErrorMessage1(err);
                      });
                  })
                  .catch(err => {
                    setErrorOtpError(formatErrorMessage(err));

                    setIsLoadingCOD(false);
                    showErrorMessage1(err);
                  });
              } else {
                fetch(urlAVVAL, {
                  method: "POST",
                  body: JSON.stringify(
                    createAVVALDataCustoms(
                      _.get(values, "paymentType", CASH),
                      getValue(values, "cardType"),
                      getValue(values, "pptId"),
                    ),
                  ),
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res && getValue(res, "code") === "1") {
                      showErrorMessage1(
                        i18n.get(fp.toString(getValue(res, "resInfo.code"))),
                      );
                      setIsLoadingCOD(false);
                    } else if (res && getValue(res, "code") === "-1") {
                      showErrorMessage1(
                        i18n.get(
                          fp.toString(getValue(res, "resInfo")),
                          getValue(res, "resInfo"),
                        ),
                      );
                      setIsLoadingCOD(false);
                    } else if (
                      res &&
                      (getValue(res, "resInfo.result.QRCodeURL") === null ||
                        !getValue(res, "resInfo.result.QRCodeURL"))
                    ) {
                      showErrorMessage1(
                        i18n.get("the_payment_was_not_successful"),
                      );
                      setIsLoadingCOD(false);
                    } else if (res && res.msg === "succes") {
                      if (checkAVVALres(res)) {
                        updateOrderIssuance(orderId, toSnakeCase(values))
                          .then(() => {
                            setReceipt(orderId, {
                              check_number: orderId,
                              payment_type: _.get(values, "paymentType", CASH),
                              type: "CUSTOMS",
                              receipt: {
                                ..._.get(res, "resInfo.result"),
                                checkID,
                              },
                            })
                              .then(() => {
                                uploadOrderSignature(orderId, {
                                  name: values.name,
                                  order_id: orderId,
                                  phone: "",
                                  photo_id: values.photoId,
                                  signature_id: values.signatureId,
                                  type: "delivery_signature",
                                })
                                  .then(res2 => {
                                    setIsRefreshOrderData(!isRefreshOrderData);
                                    showSuccessMessage1(res2.status);
                                    setIsOpenIssuanceCustoms(false);
                                    setCheckID(0);
                                    setIsOpenBrief(true);
                                  })
                                  .catch(err => {
                                    setIsLoadingCOD(false);
                                    showErrorMessage1(err);
                                  })
                                  .finally(() => {
                                    setIsLoadingCOD(false);
                                    getPDF(
                                      stateReceiptCustoms,
                                      setStateReceiptCustoms,
                                      IS_PRINTING,
                                      orderId,
                                      showErrorMessage1,
                                    );
                                  });
                              })
                              .catch(err => {
                                setIsLoadingCOD(false);
                                showErrorMessage1(err);
                              });
                          })
                          .catch(err => {
                            setErrorOtpError(formatErrorMessage(err));

                            setIsLoadingCOD(false);
                            showErrorMessage1(err);
                          });
                      }
                    } else {
                      setIsLoadingCOD(false);
                    }
                  })
                  .catch(() => {
                    setIsLoadingCOD(false);
                    showErrorMessage1(
                      i18n.get("user_is_not_connected_to_avval"),
                    );
                  });
              }
            })
            .catch(() => {
              setIsLoadingCOD(false);
            });
        }}
      />

      <BatchAssignOrdersToDriverFormDialogWrapper
        open={isOpenDispatch}
        disableBarcodes={true}
        initialValues={{
          orderBarcodes: [barcode],
          warehouse: { id: userWarehouseId },
        }}
        onRequestClose={() => {
          setIsOpenDispatch(false);
          setIsOpenBrief(true);
        }}
        onSubmitSuccess={res => {
          setIsRefreshOrderData(!isRefreshOrderData);
          setIsOpenDispatch(false);
          setBatchId(_.get(res, "data.id"));
        }}
      />

      <AdminOrderDetailsDialogWrapperForOrderList
        isRefreshOrderData={isRefreshOrderData}
        order={fromJS(order)}
        isOpen={isOpenBrief}
        setIsOpenIssuance={
          isHaveCustom ? setIsOpenIssuanceCustoms : setIsOpenIssuance
        }
        refreshOrderData={() => setIsRefreshOrderData(prev => !prev)}
        setIsOpenBrief={setIsOpenBrief}
        orderId={id}
        setIsOpenDispatch={setIsOpenDispatch}
        setIsOpenRTOPrice={setIsOpenRTOPrice}
        setIsOpenRedirect={setIsOpenRedirect}
        setIsOpenRTO={setIsOpenRTO}
        setOrderId={idNumber => setId(idNumber)}
        refreshOrderList={refreshOrderList}
        onRequestClose={() => {
          setIsOpenBrief(false);
          setOrder({});
          setId(null);
        }}
      />

      <OrdersMUITable
        filter={filter}
        setFilter={setFilter}
        isLoading={isLoading}
        isCheckboxEnabled={false}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        size="small"
        list={orderList}
        setOrderId={setId}
        setIsOpenBrief={setIsOpenBrief}
        total={total}
        page={filter.page}
        rowsPerPage={filter.size}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        columns={[
          {
            type: DIALOG,
            name: "order_number",
            keyName: "order_number",
            align: "center",
            label: i18n.get("barcode", "Barcode"),
            withoutIcon: true,
          },
          {
            type: RENDER,
            label: i18n.get("shipment_type"),
            name: "Service",
            withoutIcon: true,
            align: "left",
            render: (row, getLocalisationMessage) => (
              <FlexBox flex={true} align={ALIGN_CENTER}>
                {_.get(row, "notification.enabled") ? (
                  <OrderListServiceType order={row} />
                ) : _.get(row, "head_order_id") ? (
                  <AvatarWithName2
                    name={getLocalisationMessage("notification")}
                    isNotification={true}
                  >
                    <MultiLineCell
                      firstLine={
                        <strong style={{ textTransform: "capitalize" }}>
                          {getLocalisationMessage("notification")}
                        </strong>
                      }
                    />
                  </AvatarWithName2>
                ) : (
                  <AvatarWithName2
                    name={_.get(row, "package.delivery_label", "-")}
                    imagePath={_.get(row, "package.courier_type_icon")}
                  >
                    <MultiLineCell
                      firstLine={
                        <strong>
                          {_.get(row, "package.delivery_label", "-")}
                        </strong>
                      }
                      secondLine={_.get(row, "package.menu.name")}
                    />
                  </AvatarWithName2>
                )}
              </FlexBox>
            ),
          },
          {
            type: RENDER,
            name: "sender_name",
            align: "center",
            withoutIcon: true,
            label: i18n.get("sender_name", "Sender Name"),
            render: row => (
              <FlexBox
                flex={true}
                align={ALIGN_CENTER}
                justify={JUSTIFY_CENTER}
              >
                {_.get(row, `customer.name`)}
              </FlexBox>
            ),
          },
          {
            type: RENDER,
            name: "warehouse",
            align: "left",
            withoutIcon: true,
            label: i18n.get("warehouse", "Warehouse"),
            render: row => (
              <MultiLineCell
                firstLine={
                  <FlexBox align={ALIGN_CENTER}>
                    <CallMade
                      fontSize="small"
                      style={{
                        color: "#A8ADAF",
                        fontSize: "1rem",
                        transform: "rotate(90deg)",
                      }}
                    />
                    <span style={{ color: "#A8ADAF" }}>
                      {_.get(row, "from_warehouse.name", "-")}
                    </span>
                  </FlexBox>
                }
                secondLine={
                  <FlexBox align={ALIGN_CENTER}>
                    <CallMade
                      fontSize="small"
                      style={{
                        color: "#21C004",
                        transform: "rotate(135deg)",
                        fontSize: "1rem",
                      }}
                    />
                    <span style={{ color: "#263238" }}>
                      {_.get(row, "warehouse.name", "-")}
                    </span>
                  </FlexBox>
                }
                thirdLine={
                  <FlexBox align={ALIGN_CENTER}>
                    <CallMade
                      fontSize="small"
                      style={{
                        color: "#A8ADAF",
                        fontSize: "1rem",
                      }}
                    />
                    <span style={{ color: "#A8ADAF" }}>
                      {_.get(row, "to_warehouse.name", "-")}
                    </span>
                  </FlexBox>
                }
              />
            ),
          },
          {
            type: RENDER,
            name: "dropoff_address",
            align: "center",
            withoutIcon: true,
            label: i18n.get("dropoff_address", "DropOff Address"),
            render: row => (
              <FlexBox
                flex={true}
                align={ALIGN_CENTER}
                justify={JUSTIFY_CENTER}
              >
                {_.get(row, `locations.1.address`)}
              </FlexBox>
            ),
          },
          {
            type: RENDER,
            name: "created_date",
            keyName: "created_date",
            align: "center",
            withoutIcon: true,
            label: i18n.get("created_date", "Created Date"),
            render: row => {
              const rowChargeItems = _.get(row, "charge_items", []);
              const rowCustomFee =
                _.find(rowChargeItems, { charge_type: CUSTOMS_FEE_V2 }) || {};
              return (
                <FlexBox
                  flex={true}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                >
                  <DateTimeCell date={_.get(rowCustomFee, "date")} />
                </FlexBox>
              );
            },
          },
          {
            type: RENDER,
            name: "status",
            align: "center",
            withoutIcon: true,
            label: i18n.get("status", "status"),
            render: row => (
              <FlexBox
                flex={true}
                align={ALIGN_CENTER}
                justify={JUSTIFY_CENTER}
              >
                {formatOrderStatusCodeLocalization(_.get(row, `status`), i18n)}
              </FlexBox>
            ),
          },
          {
            type: RENDER,
            name: "customs_fee",
            align: "center",
            withoutIcon: true,
            label: i18n.get("fee_amount", "Fee amount"),
            render: row => {
              const rowChargeItems = _.get(row, "charge_items", []);
              const rowCustomFee =
                _.find(rowChargeItems, { charge_type: CUSTOMS_FEE_V2 }) || {};
              return (
                <FlexBox
                  flex={true}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                >
                  <MultiLineCell
                    style={{ paddingRight: "10px" }}
                    firstLine={
                      <PriceWrapper
                        price={_.get(rowCustomFee, "charge")}
                        type={[BOLD]}
                      />
                    }
                  />
                </FlexBox>
              );
            },
          },
          {
            type: RENDER,
            name: "customs_fee_percentage",
            align: "center",
            withoutIcon: true,
            label: i18n.get("fee_percentage", "Fee Percentage"),
            render: row => (
              <FlexBox
                flex={true}
                align={ALIGN_CENTER}
                justify={JUSTIFY_CENTER}
                style={{ fontWeight: "bold" }}
              >
                {_.get(row, "customs_fee_percentage")} %
              </FlexBox>
            ),
          },
          {
            type: RENDER,
            name: "customs_fee",
            align: "center",
            withoutIcon: true,
            label: i18n.get("total", "total"),
            render: row => {
              const rowChargeItems = _.get(row, "charge_items", []);
              const rowCustomFeePercentage =
                _.find(rowChargeItems, {
                  charge_type: CUSTOMS_FEE_PERCENTAGE,
                }) || {};
              const rowCustomFee =
                _.find(rowChargeItems, { charge_type: CUSTOMS_FEE_V2 }) || {};

              return (
                <FlexBox
                  flex={true}
                  align={ALIGN_CENTER}
                  justify={JUSTIFY_CENTER}
                >
                  <MultiLineCell
                    style={{ paddingRight: "10px" }}
                    firstLine={
                      <PriceWrapper
                        price={
                          Number(_.get(rowCustomFeePercentage, "charge")) +
                          Number(_.get(rowCustomFee, "charge"))
                        }
                        type={[BOLD]}
                      />
                    }
                  />
                </FlexBox>
              );
            },
          },
          {
            type: RENDER,
            name: "action",
            label: i18n.get("action", "Action"),
            align: "center",
            withoutIcon: true,
            render: row => (
              <FlexBox
                flex={true}
                align={ALIGN_CENTER}
                justify={JUSTIFY_CENTER}
                style={{ gap: 16 }}
              >
                <IconButton
                  onClick={() => {
                    setDeleteId(_.get(row, "id"));
                  }}
                >
                  <DeleteForever />
                </IconButton>

                <IconButton
                  onClick={() => {
                    setOrderId(_.get(row, "id"));
                  }}
                >
                  <Edit />
                </IconButton>
              </FlexBox>
            ),
          },
        ]}
        headerActions={
          <FlexBox
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_SPACE_AROUND}
            style={{ padding: ".25rem 1rem" }}
          >
            <div className={classes.search} ref={ref}>
              <Search
                fontSize="large"
                style={{
                  color: "rgba(0, 0, 0, 0.23)",
                  top: ".5rem",
                  left: ".5rem",
                  position: "absolute",
                }}
              />
              <ChipTextField
                style={{
                  padding: ".5rem 1rem 0 3rem",
                  maxHeight: divHeight > 86 && "5.8rem",
                  overflow: divHeight > 86 && "auto",
                }}
                fullWidth={true}
                addOnBlur={false}
                clearOnBlur={false}
                maxItems={1}
                value={parseString(filter.barcode)}
                disableUnderline={true}
                placeholder={i18n.get("type_here_to_search")}
                onChange={v =>
                  setFilter({ ...filter, barcode: stringifyArray(v) })
                }
              />
            </div>

            <IconButton
              className={isLoading && classes.refresh}
              tooltip={i18n.get("refresh", "Refresh")}
              onClick={() => refreshOrderList()}
            >
              <Refresh />
            </IconButton>

            <CustomButton
              onClick={() => setIsOpenCustomsFeePrice(true)}
              startIcon={<Add />}
              variant={CONTAINED}
              color={SECONDARY}
              style={{ borderRadius: "20px", marginRight: 10 }}
            >
              {i18n.get("create_new", "Create New")}
            </CustomButton>
          </FlexBox>
        }
      />
    </AdminAppLayout>
  );
};
AdminCustomsPaymentListContainer.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  customsFee: PropTypes.number,
  isOtpSettingsServiceType: PropTypes.instanceOf(List),
  isOtpSettingsTransportationType: PropTypes.instanceOf(List),
  userIdAVVAL: PropTypes.number,
  userWarehouseId: PropTypes.number,
};
export default enhancer(AdminCustomsPaymentListContainer);
