import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { connect } from "react-redux";
import FlexBox, { ALIGN_CENTER } from "../../../components/ui-core/FlexBox";
import OfflineOrderSortingTabs from "../../../components/order-outbound-sorting/OfflineOrderSortingTabs";
import { CardContent, Chip, makeStyles } from "@material-ui/core";
import ChipTextField from "../../../components/deprecated/ChipTextField";
import { DIALOG, RENDER } from "../../../components/orders-core/MUITable";
import _ from "lodash";
import {
  getUser,
  getUserPermissions,
  getUserWarehouseId,
} from "../../../reducers/ProfileReducer";
import { hasRole } from "../../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../../constants/AdminRoleTypes";
import { CONSOLIDATED } from "../../../constants/OrderType";
import { getValue } from "../../../helpers/DataUtils";
import MultiLineCell from "../../../components/data-list/MultiLineCell";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import {
  isOtpSettingsServiceType,
  isOtpSettingsTransportationType,
} from "../../../reducers/MarketplaceReducer";
import { IN_TRANSIT } from "../../../constants/OrderStatusCodes";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import fp from "lodash/fp";
import AdminBatchUpdateOrderDialogWrapper from "../../../wrappers/admin/AdminBatchUpdateOrderDialogWrapper2";
import { green } from "@material-ui/core/colors";
import OfflineOrderSortingConsolidatedTable from "../../../components/order-outbound-sorting/OfflineOrderSortingConsolidatedTable";
import { DATE } from "../../../components/orders-core/MUITableSimple";
import { compose, getContext } from "recompose";
import { parseFloat } from "../../../helpers/FormatUtils";

const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  search: {
    marginRight: "1rem",
    flex: "1 1 auto",
    position: "relative",
    borderRadius: 4,
  },
  contentRoot: {
    overflow: "hidden",
    "& .MuiTab-root": {
      minWidth: "25%",
    },
  },
  chip: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
  },
  chipSuccess: {
    cursor: "pointer",
    marginRight: 5,
    marginBottom: 5,
    backgroundColor: green[300],
    "&:hover": {
      backgroundColor: green[500],
    },
  },
  btn: {
    margin: "0 .5rem",
  },
  input: { "& input": { fontSize: "20px" } },
});

function AdminOfflineOrderOutboundConsolidatedTableContainer(props) {
  const {
    sortingBeans,
    total,
    onBeanFilter,
    filter,
    isLoading,
    getLocalisationMessage,
    sortingItems,
    handleChangeRowsPerPage,
    handleChangePage,
    selectedItems,
    setSelectedItems,
    onBeanUnFilter,
    setFilter,
    tabCount,
    setRefreshTab,
    excludeSelectedItems,
    setExcludeSelectedItems,
    refreshOrderList,
    setLocationQuery,
    location,
  } = props;
  const classes = useStyles();

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(false);

  const batchId = getValue(location, "query.batch_id");

  const [id, setId] = useState(null);

  const [divHeight, setDivHeight] = useState(0);
  const ref = useRef(false);
  const refHeight = _.get(ref, "current.clientHeight", false);

  useEffect(() => {
    if (refHeight && refHeight !== divHeight) setDivHeight(refHeight);
  }, [refHeight]);

  const [state, setState] = useState({
    open: false,
    orderStatus: null,
  });

  useEffect(() => {
    if (selectedWarehouseId) {
      const filteredByWarehouse = sortingItems.filter(
        v => getValue(v, "to_warehouse.id") === selectedWarehouseId,
      );
      setSelectedItems([...filteredByWarehouse]);
    }
  }, [selectedWarehouseId, sortingItems]);

  const barcodes =
    selectedItems && selectedItems.length
      ? selectedItems.map(item => item.barcode)
      : [];

  return (
    <FlexBox direction="column" style={{ overflow: "hidden", height: "100%" }}>
      <OfflineOrderSortingTabs
        onTabChange={props.onTabChange}
        tabValue={CONSOLIDATED}
        tabCount={tabCount}
      />

      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId || id}
        refreshBatchList={() => {
          refreshOrderList();
          setRefreshTab(prev => !prev);
        }}
        onRequestClose={() => {
          setId(null);
          refreshOrderList();
          setRefreshTab(prev => !prev);
          setLocationQuery(false);
        }}
      />

      <FlexBox
        style={{
          height: "100%",
          overflowY: "auto",
          background: "white",
          borderRadius: 4,
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        }}
        flex={true}
        direction="column"
      >
        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <AdminBatchUpdateOrderDialogWrapper
            title="create_container"
            isContainer={CONSOLIDATED}
            isSameWarehouses={selectedItems.every(
              (el, index, arr) =>
                getValue(el, "warehouse.id") ===
                getValue(arr[0], "warehouse.id"),
            )}
            open={state.open}
            initialValues={{
              batchType: "active",
              orderBarcodes: barcodes,
              orderStatus: state.orderStatus,
              warehouse: state.parentWarehouse,
              to_warehouse: state.to_warehouse,
              privacy: PUBLIC,
              weight: Number(
                parseFloat(
                  selectedItems.reduce(
                    (a, b) =>
                      Number(a) + Number(parseFloat(b.weight).toFixed(3)),
                    0,
                  ),
                ).toFixed(3),
              ),
            }}
            onRequestClose={() => {
              setState({
                open: false,
                orderStatus: null,
              });
              setSelectedWarehouseId(false);
            }}
            onSubmitFail={props.showErrorMessage}
            onSubmitSuccess={res => {
              const resId = fp.get("data.id", res);
              setLocationQuery(fp.set("batch_id", resId));
              setState({ open: false });
              setSelectedItems([]);
              refreshOrderList();
              setRefreshTab(prev => !prev);
              onBeanUnFilter();
              setSelectedWarehouseId(false);
            }}
          />

          <div
            style={{
              margin: "1rem",
              maxHeight: "20vh",
              overflow: "auto",
            }}
          >
            {sortingBeans.map((v, i) => (
              <Chip
                key={i}
                className={`${classes.chip} ${v.id === selectedWarehouseId &&
                  classes.chipSuccess}`}
                style={{
                  backgroundColor: v.id === selectedWarehouseId && green[300],
                }}
                onClick={() => {
                  if (v.id === selectedWarehouseId) {
                    setSelectedItems([]);
                    setSelectedWarehouseId(false);
                    onBeanUnFilter();
                  } else {
                    setSelectedWarehouseId(v.id);
                    onBeanFilter(v);
                  }
                }}
                label={`${v.name} (${v.count})`}
              />
            ))}
          </div>

          <OfflineOrderSortingConsolidatedTable
            filter={filter}
            setFilter={setFilter}
            isLoading={isLoading}
            isCheckboxEnabled={true}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            excludeSelectedItems={excludeSelectedItems}
            setExcludeSelectedItems={setExcludeSelectedItems}
            list={sortingItems}
            setBatchId={setId}
            total={total}
            page={filter.page}
            rowsPerPage={filter.size}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            columns={[
              {
                label: getLocalisationMessage("batch_number", "Batch Number"),
                keyName: "barcode",
                name: "Barcode",
                type: DIALOG,
                align: "center",
              },
              {
                type: DATE,
                name: "created_date",
                keyName: "created_date",
                label: getLocalisationMessage("created_date"),
              },
              {
                type: RENDER,
                name: "category",
                label: getLocalisationMessage("category"),
                render: row =>
                  getLocalisationMessage(
                    getValue(row, "category", "").toLowerCase(),
                  ),
              },
              {
                type: RENDER,
                name: "type",
                label: getLocalisationMessage("type"),
                render: row =>
                  getLocalisationMessage(
                    getValue(row, "inner_shipment_type", "").toLowerCase(),
                  ),
              },
              {
                type: RENDER,
                name: "from_warehouse.name",
                label: getLocalisationMessage("from_warehouse"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "from_warehouse.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "next_warehouse.name",
                label: getLocalisationMessage("next_warehouse"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "next_warehouse.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
              {
                type: RENDER,
                name: "to_warehouse.name",
                label: getLocalisationMessage("to_warehouse"),
                render: row => (
                  <MultiLineCell
                    firstLine={
                      <span style={{ padding: 10 }}>
                        {getValue(row, "to_warehouse.name", "N/A")}
                      </span>
                    }
                  />
                ),
              },
            ]}
            headerActions={
              <FlexBox
                align={ALIGN_CENTER}
                style={{
                  padding: ".25rem 1rem",
                  width: "100%",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                {selectedItems.length ? (
                  <React.Fragment>
                    <FlexBox flex={true}>
                      {selectedItems.length}{" "}
                      {getLocalisationMessage(
                        "items_selected",
                        "item(s) selected",
                      )}
                      .
                    </FlexBox>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className={classes.search} ref={ref}>
                      <ChipTextField
                        style={{
                          maxHeight: divHeight > 86 && "5.8rem",
                          overflow: divHeight > 86 && "auto",
                        }}
                        fullWidth={true}
                        addOnBlur={false}
                        clearOnBlur={false}
                        value={filter.barcodes}
                        disableUnderline={true}
                        placeholder={getLocalisationMessage("search")}
                        hintText={getLocalisationMessage("search")}
                        onChange={v =>
                          setFilter({
                            ...filter,
                            init: false,
                            barcodes: v,
                          })
                        }
                      />
                    </div>
                  </React.Fragment>
                )}

                {(Boolean(filter.bean) || Boolean(selectedItems.length)) && (
                  <FlexBox align="center" direction="row">
                    <CustomButton
                      fullWidth={true}
                      color={SECONDARY}
                      style={{ maxWidth: "200px" }}
                      variant={CONTAINED}
                      onClick={() => {
                        setState({
                          ...state,
                          orderStatus: IN_TRANSIT,
                          open: true,
                          parentWarehouse: fp.get(
                            "next_warehouse",
                            selectedItems[0],
                          ),
                          to_warehouse: fp.get(
                            "next_warehouse",
                            selectedItems[0],
                          ),
                        });
                      }}
                    >
                      {getLocalisationMessage("create_container")}
                    </CustomButton>
                  </FlexBox>
                )}
              </FlexBox>
            }
          />
        </CardContent>
      </FlexBox>
    </FlexBox>
  );
}

AdminOfflineOrderOutboundConsolidatedTableContainer.propTypes = {
  onTabChange: PropTypes.func,
  setRefreshTab: PropTypes.func,
  tabCount: PropTypes.object,
  setFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  sortingItems: PropTypes.array,
  sortingBeans: PropTypes.array,
  total: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  excludeSelectedItems: PropTypes.array,
  setExcludeSelectedItems: PropTypes.func,
  onBeanFilter: PropTypes.func,
  onBeanUnFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  filter: PropTypes.object,
  isLoading: PropTypes.bool,
  refreshOrderList: PropTypes.func,
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
};

const mapStateToProps = state => {
  const userRoles = getUser(state).get("roles") || [];
  return {
    isOtpSettingsServiceType: isOtpSettingsServiceType(state),
    isOtpSettingsTransportationType: isOtpSettingsTransportationType(state),
    userWarehouseId: getUserWarehouseId(state),
    permissions: getUserPermissions(state),
    isOperator: hasRole(userRoles, ROLE_OPERATOR),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  };
};

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
);

export default enhancer(AdminOfflineOrderOutboundConsolidatedTableContainer);
