import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ORDER_COUNT_BY_REGION_URL = `${API_ROOT_URL}/clickhouse/region`;
const ORDER_COUNT_BY_SERVICE_TYPE_URL = `${API_ROOT_URL}/clickhouse/service_type`;
const ORDER_COUNT_BY_PAYMENT_TYPE_URL = `${API_ROOT_URL}/clickhouse/payment_type`;
const ORDER_COUNT_BY_PAYMENT_TYPE_COD_URL = `${API_ROOT_URL}/clickhouse/payment_type_cod`;
const ORDER_TOTAL_COUNT_BY_MONTH_URL = `${API_ROOT_URL}/clickhouse/total/month`;
const ORDER_TOTAL_COUNT_BY_SERVICE_TYPE_URL = `${API_ROOT_URL}/clickhouse/total/service_type`;
const ORDER_TOTAL_COUNT_BY_CHARGE_TYPE_URL = `${API_ROOT_URL}/clickhouse/charge_type`;
const ORDER_WEIGHT_URL = `${API_ROOT_URL}/clickhouse/weight`;
const ORDER_WEIGHT_CATEGORY_URL = `${API_ROOT_URL}/clickhouse/weight_category`;
const USER_COUNT_BY_ROLE_URL = `${API_ROOT_URL}/clickhouse/user/role`;
const ORDER_COUNT_BY_DEVICE_URL = `${API_ROOT_URL}/clickhouse/device`;
const ORDER_COUNT_BY_DELIVERED_STATUS_URL = `${API_ROOT_URL}/clickhouse/delivered`;
const ORDER_COUNT_BY_SORTING_STATUS_URL = `${API_ROOT_URL}/clickhouse/sorting`;
const ORDER_COUNT_BY_STATUS_URL = `${API_ROOT_URL}/clickhouse/status`;
const ORDER_COUNT_BY_JURISDICTION_IN_URL = `${API_ROOT_URL}/clickhouse/jursidiction_in`;
const ORDER_COUNT_BY_JURISDICTION_OUT_URL = `${API_ROOT_URL}/clickhouse/jursidiction_out`;

// international
const ORDER_COUNT_BY_INTERNATIONAL_FROM_COUNTRY_URL = `${API_ROOT_URL}/clickhouse/from_international/country`;
const ORDER_COUNT_BY_INTERNATIONAL_TO_COUNTRY_URL = `${API_ROOT_URL}/clickhouse/to_international/country`;

const ORDER_COUNT_BY_INTERNATIONAL_FROM_SERVICE_TYPE_URL = `${API_ROOT_URL}/clickhouse/from_international/service_type`;
const ORDER_COUNT_BY_INTERNATIONAL_TO__SERVICE_TYPE_URL = `${API_ROOT_URL}/clickhouse/to_international/service_type`;
const INTERNATIONAL_TOTAL_URL = `${API_ROOT_URL}/clickhouse/international/total`;

export const getOrderCountByRegion = params =>
  api.get(ORDER_COUNT_BY_REGION_URL, { params });

export const getOrderCountByJurisdictionIn = params =>
  api.get(ORDER_COUNT_BY_JURISDICTION_IN_URL, { params });

export const getOrderCountByJurisdictionOut = params =>
  api.get(ORDER_COUNT_BY_JURISDICTION_OUT_URL, { params });

export const getOrderCountByServiceType = params =>
  api.get(ORDER_COUNT_BY_SERVICE_TYPE_URL, { params });

export const getOrderCountByPaymentType = params =>
  api.get(ORDER_COUNT_BY_PAYMENT_TYPE_URL, { params });

export const getOrderCountByPaymentTypeCOD = params =>
  api.get(ORDER_COUNT_BY_PAYMENT_TYPE_COD_URL, { params });

export const getOrderCreatedByChargeType = params =>
  api.get(ORDER_TOTAL_COUNT_BY_CHARGE_TYPE_URL, { params });

export const getOrderWeightByServiceType = params =>
  api.get(ORDER_WEIGHT_URL, { params });

export const getOrderWeightCategoryByServiceType = params =>
  api.get(ORDER_WEIGHT_CATEGORY_URL, { params });

export const getUserCountByRole = params =>
  api.get(USER_COUNT_BY_ROLE_URL, { params });

export const getOrderCountByDevice = params =>
  api.get(ORDER_COUNT_BY_DEVICE_URL, { params });

export const getOrderCountByDeliveredStatus = params =>
  api.get(ORDER_COUNT_BY_DELIVERED_STATUS_URL, { params });

export const getOrderCountBySortingStatus = params =>
  api.get(ORDER_COUNT_BY_SORTING_STATUS_URL, { params });

export const getOrderCountByStatus = params =>
  api.get(ORDER_COUNT_BY_STATUS_URL, { params });

export const getOrderTotalCountByMonth = params =>
  api.get(ORDER_TOTAL_COUNT_BY_MONTH_URL, { params });

export const getOrderTotalCountByServiceType = params =>
  api.get(ORDER_TOTAL_COUNT_BY_SERVICE_TYPE_URL, { params });

export const getInternationalOrderCountByFromCountry = params =>
  api.get(ORDER_COUNT_BY_INTERNATIONAL_FROM_COUNTRY_URL, { params });

export const getInternationalOrderCountByToCountry = params =>
  api.get(ORDER_COUNT_BY_INTERNATIONAL_TO_COUNTRY_URL, { params });

export const getInternationalOrderCountByFromServiceType = params =>
  api.get(ORDER_COUNT_BY_INTERNATIONAL_FROM_SERVICE_TYPE_URL, { params });

export const getInternationalOrderCountByToServiceType = params =>
  api.get(ORDER_COUNT_BY_INTERNATIONAL_TO__SERVICE_TYPE_URL, { params });

export const getInternationalTotal = params =>
  api.get(INTERNATIONAL_TOTAL_URL, { params });
