import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { connect } from "react-redux";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import { getSortingShipmentConsolidated } from "../../../api/v2/admin/AdminOrderSortingApi";
import { compose, getContext } from "recompose";
import { getUserWarehouse } from "../../../reducers/ProfileReducer";
import { Map } from "immutable";
import AdminOfflineOrderOutboundConsolidatedTableContainer from "./AdminOfflineOrderOutboundConsolidatedTableContainer";
import { getValue } from "../../../helpers/DataUtils";

function AdminOfflineOrderOutboundConsolidatedContainerWrapper(props) {
  const { getLocalisationMessage, onTabChange, tabCount } = props;
  const [sortingItems, setSortingItems] = useState([]);
  const [sortingBeans, setSortingBeans] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [excludeSelectedItems, setExcludeSelectedItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    init: true,
    page: 0,
    size: 20,
    service_type_id: null,
  });

  const [selectBin, setSelectBin] = useState([]);

  const onBeanFilter = bean => {
    setSortingItems([]);
    setSelectedItems([]);
    setSelectBin([]);
    setExcludeSelectedItems([]);

    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: false,
        page: 0,
        size: 20,
        bean,
      };

      return newObj;
    });
  };

  const onBeanUnFilter = () => {
    setSortingItems([]);
    setSelectedItems([]);
    setExcludeSelectedItems([]);

    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: false,
        page: 0,
        size: 20,
      };
      delete newObj.bean;
      return newObj;
    });
  };

  const refreshOrderList = () =>
    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: true,
        refresh: !filter.refresh,
      };

      return newObj;
    });

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  useEffect(() => {
    setIsLoading(true);
    getSortingShipmentConsolidated({
      ...filter,
      size:
        getValue(filter, "bean.count", 0) > 20
          ? getValue(filter, "bean.count")
          : filter.size,
    })
      .then(res => {
        if (res) {
          setSortingItems(res.items);
          if (res.beans) {
            setSortingBeans(res.beans);
          }
          setTotal(res.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  }, [filter]);

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "outbound_sorting",
        "Outbound Sorting",
      )} | ${props.userWarehouse.get("name")}`}
    >
      <AdminOfflineOrderOutboundConsolidatedTableContainer
        onTabChange={onTabChange}
        location={props.location}
        setRefreshTab={props.setRefreshTab}
        tabCount={tabCount}
        tabValue={filter.type}
        sortingItems={sortingItems}
        sortingBeans={sortingBeans}
        total={total}
        refreshOrderList={refreshOrderList}
        onBeanFilter={onBeanFilter}
        onBeanUnFilter={onBeanUnFilter}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        filter={filter}
        isLoading={isLoading}
        selectedItems={selectedItems}
        setFilter={setFilter}
        setSelectedItems={setSelectedItems}
        excludeSelectedItems={excludeSelectedItems}
        setExcludeSelectedItems={setExcludeSelectedItems}
        selectBin={selectBin}
        setSelectBin={setSelectBin}
      />
    </AdminAppLayout>
  );
}

AdminOfflineOrderOutboundConsolidatedContainerWrapper.propTypes = {
  userWarehouse: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setRefreshTab: PropTypes.func,
  onTabChange: PropTypes.func,
  tabCount: PropTypes.string,
  location: PropTypes.string,
};

const mapStateToProps = state => ({
  userWarehouse: getUserWarehouse(state),
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

export default enhancer(AdminOfflineOrderOutboundConsolidatedContainerWrapper);
