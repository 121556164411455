import React, { useCallback, useContext, useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import FormWarehouseDialog from "../../../components/form/FormWarehouseDialog";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import { fromJS, Map } from "immutable";
import {
  addBatchSortingTaskOrderNumber,
  clearOrderSortingBatchOrders,
  getOrderSortingTask,
  getOrderSortingTaskBatchRootOrder,
  updateSortingTask,
} from "../../../reducers/OrderInboundSortingReducer";
import OrderSortingBinValidationForm from "../../../components/order-inbound-sorting/OrderSortingBinValidationForm";
import { Card, CardContent, CardHeader, MenuItem } from "@material-ui/core";
import { getValue, toJS } from "../../../helpers/DataUtils";
import FlexBox, {
  JUSTIFY_SPACE_AROUND,
} from "../../../components/ui-core/FlexBox";
import fp from "lodash/fp";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import ConfirmDialog from "../../../components/deprecated/ConfirmDialog";
import { batchAsyncUpdateOrder } from "../../../api/admin/AdminOrderApi";
import { SENT_TO_CUSTOMS } from "../../../constants/OrderStatusCodes";
import { green, grey, red } from "@material-ui/core/colors";
import { CheckCircle, HourglassEmpty, ReportProblem } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import {
  getUserInboundKey,
  getUserLogin,
  getUserWarehouse,
  getUserWarehouseId,
  getUserWarehousesIds,
  hasUserPermission,
} from "../../../reducers/ProfileReducer";
import { validateUserWarehouse } from "../../../helpers/OrderSortingHelper";
import _ from "lodash";
import { GlobalContext } from "../../shared/ClientApp";
import cx from "classnames";
import { PUBLIC } from "../../../constants/NotePrivacyTypes";
import AdminBatchUpdatesItemDialogWrapper from "../../../wrappers/admin/AdminBatchUpdatesItemDialogWrapper";
import { getAppToken } from "../../../../shared/reducers/AppReducer";
import {
  inboundClear,
  inboundComplete,
  inboundOpen,
  inboundScan,
} from "../../../api/v2/admin/AdminOrderSortingApi";
import { supabase } from "../../../../shared/config/SupabaseConfig";
import OrderOfflineSortingBinDetailsCard from "../../../components/order-inbound-sorting/OrderOfflineSortingBinDetailsCard";
import OrderOfflineSortingBinHeirarchyCard from "../../../components/order-inbound-sorting/OrderOfflineSortingBinHeirarchyCard";
import OrderOfflineInboundSortingVerifyOrdersDialog from "../../../components/order-inbound-sorting/OrderOfflineInboundSortingVerifyOrdersDialog";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  bottomSpace: {
    marginBottom: 8,
    minHeight: 230,
    "@media (min-width: 401px) and (max-width: 991px)": {
      minHeight: 350,
    },
    "@media (max-width: 400px)": {
      minHeight: 370,
    },
  },
  iconsWrapper: {
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  card: {
    padding: "15px",
    "@media (max-width: 991px)": {
      marginBottom: 15,
      flex: "none",
    },
  },
  cardLeft: {
    marginRight: 4,
    "@media (max-width: 991px)": {
      marginRight: -8,
    },
  },
  cardRight: {
    marginLeft: 4,
    "@media (max-width: 991px)": {
      marginLeft: -8,
    },
  },
  overallInfo: {
    "@media (max-width: 991px)": {
      flexDirection: "column",
      "& div > span": {
        display: "none",
      },
    },
  },
  content: {
    justifyContent: "space-around",
    "@media (max-width: 991px)": {
      justifyContent: "flex-start",
    },
  },
}));

const enhancer = compose(
  withTheme,
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  connect(
    state => {
      const task = getOrderSortingTask(state);
      const userWarehouse = toJS(getUserWarehouse(state));
      return {
        task,
        userWarehouse,
        token: getAppToken(state),
        inboundKey: getUserInboundKey(state),
        userWarehouseId: getUserWarehouseId(state),
        userLogin: getUserLogin(state),
        userWarehousesIds: getUserWarehousesIds(state),
        warehouseId: task.getIn(["warehouse", "id"]),
        cursorOrder: getOrderSortingTaskBatchRootOrder(state),
        hasCustomsPermission: hasUserPermission(
          state,
          "INBOUND_SORTING_SENT_TO_CUSTOMS",
        ),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
      updateSortingTask,
      addBatchSortingTaskOrderNumber,
      clearOrderSortingBatchOrders,
    },
  ),
);

AdminOfflineInboundSortingContainer.propTypes = {
  warehouseId: PropTypes.number,
  userWarehouseId: PropTypes.number,

  userWarehousesIds: PropTypes.array,

  task: PropTypes.instanceOf(Map),
  updateSortingTask: PropTypes.func,
  clearOrderSortingBatchOrders: PropTypes.func,

  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,

  userWarehouse: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  addBatchSortingTaskOrderNumber: PropTypes.func,
  hasCustomsPermission: PropTypes.bool,
  inboundKey: PropTypes.string,
  userLogin: PropTypes.string,
};

function AdminOfflineInboundSortingContainer(props) {
  const { setUW } = useContext(GlobalContext);
  const classes = useStyles();
  const {
    getLocalisationMessage,
    task,
    inboundKey,
    userLogin,
    location: { query },
  } = props;
  const [batchId, setBatchId] = useState(false);
  const [orders, setOrders] = useState([]);
  const [hierarchyOrders, setHierarchyOrders] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState(null);
  const [shouldOpenBarcode, setShouldOpenBarcode] = useState(null);
  const [total, setTotal] = useState({});
  const activeBatchActiveOrderNumber = task.get("activeBatchActiveOrderNumber");

  const onScan = useCallback(barcode => {
    if (barcode) {
      setIsLoading(true);
      props.addBatchSortingTaskOrderNumber(fromJS([barcode]));
      inboundScan({ barcode })
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const onOpen = useCallback(barcode => {
    if (barcode) {
      setIsLoading(true);
      props.addBatchSortingTaskOrderNumber(fromJS([barcode]));
      inboundOpen(barcode)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  function countBarcodesAndScanned(items) {
    let totalBarcodes = 0;
    let totalScanned = 0;

    function processItem(item) {
      if (item.barcode) totalBarcodes++;
      if (item.scanned === "active") totalScanned++;
      if (item.batch && item.batch.children) {
        item.batch.children.forEach(child => processItem(child));
      }
    }

    items.forEach(item => processItem(item));

    return { totalBarcodes, totalScanned };
  }

  const updateChildrenRecursively = (children, ordersChildren) =>
    children && children.length > 0
      ? children.map(child => {
          const childBarcode = getValue(child, "barcode");

          const matchingOrder = ordersChildren.find(
            item => item.barcode === childBarcode,
          );

          if (matchingOrder) {
            const updatedChild = { ...matchingOrder };

            if (updatedChild.batch && updatedChild.batch.children) {
              updatedChild.batch.children = updateChildrenRecursively(
                updatedChild.batch.children,
                ordersChildren,
              );
            }

            return updatedChild;
          }

          if (child.batch && child.batch.children) {
            // eslint-disable-next-line no-param-reassign
            child.batch.children = updateChildrenRecursively(
              child.batch.children,
              ordersChildren,
            );
          }

          return {
            ...child,
            scanned: "inactive",
          };
        })
      : [];

  const updateOrdersAndChildren = ordersChildren => {
    const itemsToRemove = new Set();

    ordersChildren.forEach(order => {
      const children = getValue(order, "batch.children", []);
      // eslint-disable-next-line no-unused-expressions
      children && children.length > 0
        ? children.forEach(child => {
            itemsToRemove.add(getValue(child, "barcode"));
          })
        : [];
    });

    let updatedOrders = ordersChildren.filter(order => {
      const orderBarcode = getValue(order, "barcode");
      return !itemsToRemove.has(orderBarcode);
    });

    updatedOrders = updatedOrders.map(order => {
      const batchChildren = getValue(order, "batch.children", []);
      const newBatchChildren = updateChildrenRecursively(
        batchChildren,
        ordersChildren,
      );

      return {
        ...order,
        batch: {
          ...getValue(order, "batch", {}),
          children: newBatchChildren,
        },
      };
    });

    return updatedOrders;
  };

  async function fetchData() {
    if (inboundKey && props.warehouseId) {
      const { data } = await supabase
        .from(`inbound_${inboundKey}`)
        .select()
        .eq("warehouse_id", props.warehouseId);

      const ordersData =
        data && data.length
          ? data.map(item => {
              if (item.state === "PENDING") {
                return {
                  ...item,
                  isLoading: true,
                };
              }

              return {
                ...item,
                scanned: "active",
              };
            })
          : [];

      setOrders(ordersData);
    }
  }

  useEffect(() => {
    if (task.get("inProgress", false)) {
      fetchData();
    }
  }, [refresh, inboundKey, props.warehouseId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (props.warehouseId && inboundKey) {
      const handleUpdate = payload => {
        if (payload.eventType === "INSERT") {
          setOrders(prev => {
            const prevBarcodes = prev.map(item => item.barcode);
            return prevBarcodes.includes(getValue(payload, "new.barcode"))
              ? prev.map(item => {
                  if (item.barcode === getValue(payload, "new.barcode")) {
                    return {
                      ...item,
                      ...getValue(payload, "new"),
                      isLoading: true,
                    };
                  }
                  return item;
                })
              : [
                  ...prev,
                  {
                    ...getValue(payload, "new"),
                    isLoading: true,
                  },
                ];
          });
        }

        if (payload.eventType === "UPDATE") {
          setOrders(prev => {
            const prevBarcodes = prev.map(item => item.barcode);
            return prevBarcodes.includes(getValue(payload, "new.barcode"))
              ? prev.map(item => {
                  if (item.barcode === getValue(payload, "new.barcode")) {
                    return {
                      ...item,
                      ...getValue(payload, "new"),
                      scanned:
                        getValue(payload, "new.state") === "PENDING"
                          ? "inactive"
                          : "active",
                      isLoading: getValue(payload, "new.state") === "PENDING",
                    };
                  }
                  return item;
                })
              : [
                  ...prev,
                  {
                    ...getValue(payload, "new"),
                    scanned:
                      getValue(payload, "new.state") === "PENDING"
                        ? "inactive"
                        : "active",
                    isLoading: getValue(payload, "new.state") === "PENDING",
                  },
                ];
          });
        }

        if (payload.eventType === "DELETE") {
          setRefresh(prev => !prev);
          setOrders([]);
        }
      };

      const subscribeToChanges = () => {
        const channel = supabase
          .channel("schema-db-changes")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: `inbound_${inboundKey}`,
              filter: `warehouse_id=eq.${props.warehouseId}`,
            },
            handleUpdate,
          )
          .subscribe();

        return channel;
      };

      subscribeToChanges();

      return async () => {
        await supabase.removeChannel(subscribeToChanges());
      };
    }
  }, [props.warehouseId, inboundKey]);

  useEffect(() => {
    if (orders && orders.length > 0) {
      setHierarchyOrders(updateOrdersAndChildren(orders));
    } else {
      setHierarchyOrders([]);
    }
  }, [orders]);

  useEffect(() => {
    if (hierarchyOrders && hierarchyOrders.length > 0) {
      setTotal(countBarcodesAndScanned(hierarchyOrders));
    } else {
      setTotal({});
    }
  }, [hierarchyOrders]);

  if (!validateUserWarehouse(props.warehouseId, props.userWarehousesIds)) {
    return (
      <FormWarehouseDialog
        open={true}
        isDisabled={!fp.size(props.userWarehousesIds) > 1}
        initialValues={{ warehouse: props.userWarehouse }}
        includeWarehouses={props.userWarehousesIds}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        onSubmit={values => {
          setUW(_.get(values, "warehouse.id"));
          props.updateSortingTask(() =>
            Map({ warehouse: Map(values.warehouse) }),
          );
        }}
      />
    );
  }

  const orderBarcodesForCustoms = orders
    .filter(x => x.orders)
    .map(x => x.barcode);

  return (
    <AdminAppLayout
      title={getLocalisationMessage("inbound_sorting")}
      appBarRightAction={
        <FlexBox>
          {props.hasCustomsPermission && orders.length > 0 && (
            <CustomButton
              style={{ margin: ".5rem" }}
              size="small"
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => {
                const request = {
                  order_status: SENT_TO_CUSTOMS,
                  warehouse: { id: props.warehouseId },
                  order_barcodes: orderBarcodesForCustoms,
                  privacy: PUBLIC,
                };

                batchAsyncUpdateOrder(request)
                  .catch(error => props.showErrorMessage(error))
                  .then(response => {
                    setBatchId(fp.get("data.id", response));
                  });
              }}
            >
              {getLocalisationMessage("finish_and_sent_to_customs")}
            </CustomButton>
          )}
          {orders.length > 0 && (
            <CustomButton
              style={{ margin: ".5rem 0" }}
              size="small"
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => props.setLocationQuery(fp.set("finish", true))}
            >
              {getLocalisationMessage("finish")}
            </CustomButton>
          )}

          <MenuButtonMore color={props.theme.palette.appBarTextColor}>
            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("remove_all", true))}
            >
              {getLocalisationMessage("remove_all", "Remove All")}
            </MenuItem>

            <MenuItem
              onClick={() => props.setLocationQuery(fp.set("log_out", true))}
            >
              {getLocalisationMessage("log_out_warehouse", "Log Out Warehouse")}
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      }
    >
      <AdminBatchUpdatesItemDialogWrapper
        batchId={batchId}
        onRequestClose={() => {
          props.clearOrderSortingBatchOrders();
          props.updateSortingTask(t => t.set("inProgress", false));
          inboundClear()
            .then(r => r)
            .catch(e => e);

          setRefresh(prev => !prev);

          setBatchId(false);
          setOrders([]);
        }}
      />

      {query.remove_all === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("remove_all"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask(t => t.set("inProgress", false));
            inboundClear()
              .then(r => r)
              .catch(e => e);

            setRefresh(prev => !prev);
            props.setLocationQuery(fp.unset("remove_all"));
            setOrders([]);
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_remove_all_data",
            "Are you sure you want to remove all data?",
          )}
        </ConfirmDialog>
      )}

      {scannedBarcode && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => setScannedBarcode(null)}
          onConfirm={() => {
            setScannedBarcode(null);

            return onScan(fp.toUpper(scannedBarcode));
          }}
        >
          {`${getLocalisationMessage("your_current_warehouse_is")} - `}
          <strong>{getValue(props, "userWarehouse.name")}</strong>
          {" ?"}
        </ConfirmDialog>
      )}

      {shouldOpenBarcode && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => setShouldOpenBarcode(null)}
          onConfirm={() => {
            onOpen(shouldOpenBarcode);
            setShouldOpenBarcode(null);
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_open_registry",
            "Are you sure you want to open Registry?",
          )}
        </ConfirmDialog>
      )}

      {query.log_out === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask(x => x.clear());

            inboundClear()
              .then(r => r)
              .catch(e => e);
            setRefresh(prev => !prev);
            props.setLocationQuery(fp.unset("log_out"));
            setOrders([]);
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_log_out_warehouse",
            "Are you sure you want to log out warehouse?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      {query.finish === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => props.setLocationQuery(fp.unset("finish"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask(t => t.set("inProgress", false));

            inboundComplete()
              .then(r => r)
              .catch(e => e);
            setRefresh(prev => !prev);
            props.setLocationQuery(fp.unset("finish"));
            setOrders([]);
          }}
        >
          {getLocalisationMessage(
            "are_you_sure_you_want_to_finish",
            "Are you sure you want to finish?",
          )}
          <br />
          {getLocalisationMessage(
            "it_would_remove_all_your_local_data",
            "It would remove all your local data.",
          )}
        </ConfirmDialog>
      )}

      {false && query.log_out === "true" && orders.length > 0 && (
        <OrderOfflineInboundSortingVerifyOrdersDialog
          open={true}
          orders={orders}
          warehouseId={props.warehouseId}
          onRequestClose={() => props.setLocationQuery(fp.unset("log_out"))}
          onConfirm={() => {
            props.clearOrderSortingBatchOrders();
            props.updateSortingTask(t => t.set("inProgress", false));
            inboundClear()
              .then(r => r)
              .catch(e => e);
            props.setLocationQuery(fp.unset("log_out"));
            setRefresh(prev => !prev);
            setOrders([]);
          }}
        />
      )}

      {task.get("inProgress", false) ? (
        <FlexBox container={8} direction="column" className={classes.root}>
          <FlexBox
            element={<Card />}
            direction="column"
            className={classes.bottomSpace}
          >
            <CardHeader title={<h5>{task.getIn(["warehouse", "name"])} </h5>} />
            <CardContent>
              <FlexBox
                flex={true}
                style={{ marginBottom: "5px", fontSize: 20 }}
              >
                <FlexBox
                  flex={true}
                  className={classes.overallInfo}
                  justify={JUSTIFY_SPACE_AROUND}
                >
                  <div>
                    <strong>
                      {getLocalisationMessage("total", "Total ")}:
                    </strong>{" "}
                    {getValue(total, "totalBarcodes", 0)}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {getLocalisationMessage("need_to_scan", "Need to Scan")}:
                    </strong>{" "}
                    {getValue(total, "totalBarcodes", 0) -
                      getValue(total, "totalScanned", 0)}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                  <div>
                    <strong>
                      {getLocalisationMessage(
                        "scanned_orders",
                        "Scanned Items",
                      )}
                      :
                    </strong>{" "}
                    {getValue(total, "totalScanned", 0)}{" "}
                    <span>
                      (
                      {getLocalisationMessage(
                        "batches_shipments",
                        "Batches/Shipments",
                      )}
                      ){" "}
                    </span>
                  </div>
                </FlexBox>
              </FlexBox>
              <OrderSortingBinValidationForm
                focusInput={!!isLoading}
                onSubmit={x => {
                  if (props.warehouseId !== props.userWarehouseId) {
                    setScannedBarcode(fp.toUpper(x[0]));
                  } else {
                    onScan(fp.toUpper(x[0]));
                  }
                }}
              />

              <FlexBox className={classes.iconsWrapper}>
                <FlexBox>
                  <HourglassEmpty style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("loading", "Loading")}</span>
                </FlexBox>

                <FlexBox>
                  <ReportProblem style={{ color: red[500] }} />
                  <span>{getLocalisationMessage("error", "Error")}</span>
                </FlexBox>

                <FlexBox>
                  <CheckCircle style={{ color: green[500] }} />
                  <span>{getLocalisationMessage("success", "Success")}</span>
                </FlexBox>

                <FlexBox>
                  <CheckCircle style={{ color: grey[500] }} />
                  <span>{getLocalisationMessage("inactive", "Inactive")}</span>
                </FlexBox>
              </FlexBox>
            </CardContent>
          </FlexBox>

          <FlexBox flex={true}>
            <FlexBox
              container={16}
              flex={true}
              xsDirection="column"
              lgDirection="row"
              className={classes.content}
            >
              <FlexBox
                direction="column"
                justify={JUSTIFY_SPACE_AROUND}
                flex={true}
                lg={6}
                gutter={16}
                className={cx(classes.card, classes.cardLeft)}
                element={<Card />}
              >
                <OrderOfflineSortingBinDetailsCard
                  activeOrder={activeBatchActiveOrderNumber}
                  order={orders.find(
                    item => item.barcode === activeBatchActiveOrderNumber,
                  )}
                  onReloadClick={barcode => onScan(fp.toUpper(barcode))}
                />
              </FlexBox>

              <FlexBox
                direction="column"
                flex={true}
                lg={6}
                gutter={16}
                className={cx(classes.card, classes.cardRight)}
                element={<Card />}
              >
                <FlexBox>
                  <OrderOfflineSortingBinHeirarchyCard
                    userLogin={userLogin}
                    simpleOrders={orders}
                    warehouseId={props.warehouseId}
                    onOpenRegistry={barcode => {
                      setShouldOpenBarcode(fp.toUpper(barcode));
                    }}
                    onRetry={barcode => {
                      onScan(fp.toUpper(barcode));
                    }}
                    orders={hierarchyOrders}
                    cursorOrder={activeBatchActiveOrderNumber}
                    onRetryAll={() => setRefresh(prev => !prev)}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox container={8} direction="column" className={classes.root}>
          <FlexBox
            element={<Card />}
            direction="column"
            justify="center"
            className={classes.bottomSpace}
          >
            <FlexBox direction="column" align="center">
              <CardHeader
                title={<h5>{task.getIn(["warehouse", "name"])} </h5>}
              />

              <CustomButton
                variant={CONTAINED}
                color={SECONDARY}
                size="large"
                onClick={() => {
                  props.updateSortingTask(t => t.set("inProgress", true));
                  setRefresh(prev => !prev);
                }}
              >
                {getLocalisationMessage(
                  "start_inbound_process",
                  "Start Inbound Process",
                )}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </AdminAppLayout>
  );
}

export default enhancer(AdminOfflineInboundSortingContainer);
