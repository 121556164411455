import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { connect } from "react-redux";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import { getSortingShipmentOrders } from "../../../api/v2/admin/AdminOrderSortingApi";
import AdminOrderOfflineOutboundSortingTableContainer from "./AdminOrderOfflineOutboundSortingTableContainer";
import { Button, makeStyles, MenuItem } from "@material-ui/core";
import FlexBox from "../../../components/ui-core/FlexBox";
import { FilterList as FilterListIcon, Refresh } from "@material-ui/icons";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import MenuButtonMore from "../../../components/ui-core/MenuButtonMore";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../../api/shared/CountryV2Api";
import OfflineOrderSortingRuleListDialog from "../../../components/order-outbound-sorting/OfflineOrderSortingRuleListDialog";
import { compose, getContext } from "recompose";
import {
  getUser,
  getUserWarehouse,
  hasUserPermission,
} from "../../../reducers/ProfileReducer";
import { Map } from "immutable";
import AdminOfflineOrderFilterWrapperForOrderList from "./AdminOfflineOrderFilterWrapperForOrderList";
import PageLoading from "../../../components/ui-core/PageLoading";
import { hasRole } from "../../../helpers/RoleUtils";
import { ROLE_OPERATOR } from "../../../constants/AdminRoleTypes";
import AdminShiftingHandoverVerifyDialogWrapper from "../../../components/order-outbound-sorting/AdminShiftingHandoverVerifyDialogWrapper";
import ShiftingDialog from "../../../components/order-outbound-sorting/ShiftingDialog";
import AdminShiftingAcceptDialogWrapper from "../../../components/order-outbound-sorting/AdminShiftingAcceptDialogWrapper";
import { SHIFTING_HISTORY_URL } from "../../../constants/AdminPathConstants";
import { getValue } from "../../../helpers/DataUtils";
import fp from "lodash/fp";

const filterField = [
  "service_types",
  "from_date_time",
  "to_date_time",
  "operator_id",
  "source_warehouse_id",
  "destination_warehouse_id",
  "payment_types",
  "to_jurisdiction_ids",
  "from_jurisdiction_ids",
  "statuses",
];

const useStyles = makeStyles({
  refresh: {
    transform: "translate3d(0, 0, 0)",
    animation: "rotate 1s ease 0s infinite normal",
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

function AdminOrderOfflineOutboundSortingContainer(props) {
  const {
    getLocalisationMessage,
    onTabChange,
    tabCount,
    isOperator,
    savedFilter,
    canShowShift,
    sortingRuleView,
  } = props;
  const classes = useStyles();

  const [sortingItems, setSortingItems] = useState([]);
  const [sortingServiceTypes, setSortingServiceTypes] = useState([]);
  const [sortingBeans, setSortingBeans] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [excludeSelectedItems, setExcludeSelectedItems] = useState([]);
  const [showBinDialog, setShowBinDialog] = useState(false);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(null);
  const [filter, setFilter] = useState({
    ...savedFilter,
    init: true,
    page: 0,
    size: 20,
    service_type_id: null,
  });
  const [isSelected, setIsSelected] = React.useState(false);

  const [selectBean, setSelectBean] = useState({});
  const openShifting = getValue(props.location, "query.shift");
  const [openHandover, setOpenHandover] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);

  const [isRegroupLoading, setIsRegroupLoading] = useState(false);
  const [selectBin, setSelectBin] = useState([]);

  const onServiceTypeFilter = value => {
    setSortingBeans([]);
    setSortingItems([]);
    setSelectedItems([]);
    setSelectBin([]);
    setSelectBean({});
    setIsSelected(false);
    // setExcludeSelectedItems([]);

    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: true,
        page: 0,
        size: 20,
        service_type_id: value,
      };
      delete newObj.bean;

      return newObj;
    });
  };

  const onBeanFilter = bean => {
    setSortingItems([]);
    setSelectedItems([]);
    setSelectBin([]);
    // setExcludeSelectedItems([]);
    setSelectBean(bean);

    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: false,
        page: 0,
        size: 20,
        bean,
      };

      delete newObj.barcodes;

      return newObj;
    });
  };

  const onBeanUnFilter = () => {
    setSortingItems([]);
    setSelectedItems([]);
    // setExcludeSelectedItems([]);
    setSelectBin([]);
    setSelectBean({});
    setIsSelected(false);

    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: false,
        page: 0,
        size: 20,
      };
      delete newObj.bean;
      return newObj;
    });
  };

  const refreshOrderList = () => {
    props.setRefreshTab(prev => !prev);
    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: true,
        refresh: !filter.refresh,
      };

      return newObj;
    });
  };

  const handleChangePage = (event, page) => setFilter({ ...filter, page });
  const handleChangeRowsPerPage = event =>
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });

  useEffect(() => {
    setIsLoading(true);
    getSortingShipmentOrders(
      filter && filter.barcodes && filter.barcodes.length > 1
        ? {
            ...filter,
            size:
              filter && filter.barcodes.length > filter.size
                ? filter.barcodes.length
                : filter.size,
          }
        : filter,
    )
      .then(res => {
        if (res) {
          if (filter && filter.barcodes && filter.barcodes.length > 1) {
            if (
              res &&
              res.items &&
              res.items.length > 0 &&
              sortingItems.length > 0
            ) {
              setSortingItems(prev =>
                [...prev, ...res.items].filter(
                  (obj, index, self) =>
                    index === self.findIndex(o => o.barcode === obj.barcode),
                ),
              );
            } else if (
              res &&
              res.items &&
              res.items.length > 0 &&
              sortingItems.length === 0
            ) {
              setSortingItems(res.items);
            } else {
              setSortingItems([]);
            }
          } else {
            setSortingItems(res.items);
          }
          if (res.beans) {
            setSortingBeans(res.beans);
          }
          if (res.services) {
            setSortingServiceTypes([
              {
                id: null,
                code: "all",
                name: "All",
                count: res.total,
              },
              ...res.services,
            ]);
          }

          setTotal(res.total);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  }, [filter]);

  const isFilter = Object.keys(filter).filter(item =>
    filterField.includes(item),
  );

  return (
    <AdminAppLayout
      title={
        isOperator
          ? `${getLocalisationMessage(
              "order_sorting_operator",
              "Order Sorting",
            )} | ${props.userWarehouse.get("name")}`
          : `${getLocalisationMessage(
              "order_sorting",
              "Order Sorting",
            )} | ${props.userWarehouse.get("name")}`
      }
      appBarRightAction={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-end"
          style={{ gap: 13 }}
        >
          {canShowShift && (
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => props.setLocationQuery(fp.set("shift", true))}
            >
              {getLocalisationMessage("change_shift", "Change Shift")}
            </CustomButton>
          )}

          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => refreshOrderList()}
          >
            <Refresh className={isLoading && classes.refresh} />
          </CustomButton>

          {isFilter && isFilter.length > 0 ? (
            <Button
              variant={CONTAINED}
              style={{
                backgroundColor: "#F07824",
                color: "#fff",
                padding: 8,
              }}
              onClick={() => setOpenFilter(true)}
            >
              <FilterListIcon />
            </Button>
          ) : (
            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              onClick={() => setOpenFilter(true)}
            >
              <FilterListIcon />
            </CustomButton>
          )}

          {sortingRuleView && (
            <MenuButtonMore>
              <MenuItem onClick={() => setShowBinDialog(true)}>
                {props.getLocalisationMessage("view_rules", "View Rules")}
              </MenuItem>
            </MenuButtonMore>
          )}
        </FlexBox>
      }
    >
      <PageLoading isLoading={isRegroupLoading} />

      <ShiftingDialog
        open={openShifting}
        onRequestClose={() => props.setLocationQuery(fp.unset("shift"))}
        shiftHandOver={() => {
          setOpenHandover(true);
          props.setLocationQuery(fp.unset("shift"));
        }}
        shiftAccept={() => {
          props.setLocationQuery(fp.unset("shift"));
          setOpenAccept(true);
        }}
        shiftHistory={() => props.setLocation(SHIFTING_HISTORY_URL)}
      />

      <AdminShiftingHandoverVerifyDialogWrapper
        open={openHandover}
        onRequestClose={() => {
          setOpenHandover(false);
          props.setLocationQuery(fp.set("shift", true));
        }}
        onSubmitSuccess={() => {
          setOpenHandover(false);
          refreshOrderList();
          props.setRefreshTab(prev => !prev);
        }}
      />

      <AdminShiftingAcceptDialogWrapper
        open={openAccept}
        onRequestClose={() => {
          setOpenAccept(false);
          props.setLocationQuery(fp.set("shift", true));
        }}
        onSubmitSuccess={() => {
          setOpenAccept(false);
          refreshOrderList();
          props.setRefreshTab(prev => !prev);
        }}
      />

      <AdminOfflineOrderFilterWrapperForOrderList
        showLogistic={true}
        setIsSelected={setIsSelected}
        filter={filter}
        sorting={true}
        setFilter={setFilter}
        withCustomFilters={true}
        open={openFilter}
        onRequestClose={() => setOpenFilter(false)}
        closeDialog={() => {
          setOpenFilter(false);
          setSortingBeans([]);
          setSortingServiceTypes([]);
          setSortingItems([]);
          setSelectedItems([]);
          // setExcludeSelectedItems([]);
        }}
      />

      {showBinDialog && (
        <OfflineOrderSortingRuleListDialog
          onBeanUnFilter={onBeanUnFilter}
          refreshOrderList={() => {
            setFilter({
              ...savedFilter,
              init: true,
              page: 0,
              size: 20,
              service_type_id: null,
            });
          }}
          setIsOrderLoading={setIsRegroupLoading}
          open={showBinDialog}
          onRequestClose={() => setShowBinDialog(false)}
          onSubmitSuccess={() => setShowBinDialog(false)}
          onSubmitFail={props.showErrorMessage}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      )}

      <AdminOrderOfflineOutboundSortingTableContainer
        location={props.location}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        savedFilter={savedFilter}
        onTabChange={onTabChange}
        selectBean={selectBean}
        setSelectBean={setSelectBean}
        tabCount={tabCount}
        setRefreshTab={props.setRefreshTab}
        tabValue={filter.type}
        sortingItems={sortingItems}
        sortingServiceTypes={sortingServiceTypes}
        sortingBeans={sortingBeans}
        total={total}
        refreshOrderList={refreshOrderList}
        onServiceTypeFilter={onServiceTypeFilter}
        onBeanFilter={onBeanFilter}
        onBeanUnFilter={onBeanUnFilter}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        filter={filter}
        isLoading={isLoading}
        selectedItems={selectedItems}
        // excludeSelectedItems={excludeSelectedItems}
        setFilter={setFilter}
        setSelectedItems={setSelectedItems}
        // setExcludeSelectedItems={setExcludeSelectedItems}
        setIsLoading={setIsRegroupLoading}
        selectBin={selectBin}
        setSelectBin={setSelectBin}
      />
    </AdminAppLayout>
  );
}

AdminOrderOfflineOutboundSortingContainer.propTypes = {
  userWarehouse: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  onTabChange: PropTypes.func,
  setRefreshTab: PropTypes.func,
  tabCount: PropTypes.func,
  setLocation: PropTypes.func,
  setLocationQuery: PropTypes.func,
  location: PropTypes.object,
  savedFilter: PropTypes.object,
  isOperator: PropTypes.bool,
  canShowShift: PropTypes.bool,
  sortingRuleView: PropTypes.bool,
};

const mapStateToProps = state => {
  const userRoles = getUser(state).get("roles") || [];
  return {
    isOperator: hasRole(userRoles, ROLE_OPERATOR),
    canShowShift: hasUserPermission(state, "ADMIN_ORDER_SHIFT"),
    sortingRuleView: hasUserPermission(state, "ORDER_SORTING_RULE_VIEW"),
    userWarehouse: getUserWarehouse(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  };
};

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

export default enhancer(AdminOrderOfflineOutboundSortingContainer);
